import React from 'react';
import Data from '../../../components/Backoffice/Display/Data';
import MultipleDataTag from '../../../components/Backoffice/Display/MultipleDataTag';
import DataTag from '../../../components/Backoffice/Display/DataTag';
import { getConstant } from '../../../utils/constants';
import Section from '../../../components/Backoffice/Display/Section';
import MultiLineData from '../../../components/Backoffice/Display/MultiLineData';
import { formatDateString } from '../../../utils/stringHelper';

function ResidencyInformation({ residency, handleResidencyUpdate, loading }) {
  const updateResidencyAction = {
    onClick: handleResidencyUpdate,
    label: 'Modifier les informations',
    icon: 'pi-pencil',
    loading
  };

  return (
    <Section label="Informations générales" actions={[updateResidencyAction]}>
      {/* Informations */}
      <div className="data-section-row">
        <Data key="label" label={'Nom'} value={residency.label} large />
        <Data
          key="projectDeveloper"
          label={'Porteur.euse de projet'}
          value={residency.project_developer}
          large
        />

        <MultipleDataTag
          key="tags"
          label={'Tags'}
          tags={residency.properties.map((property) => ({
            label: property.label
          }))}
          rounded
        />
      </div>
      <div className="data-section-row">
        <DataTag
          key="type"
          label={'Type'}
          tag={getConstant('backoffice.residency.typeList', residency.type)}
          rounded
          large
        />
        <DataTag
          key="state"
          label={'État'}
          tag={getConstant('backoffice.residency.stateList', residency.state)}
          rounded
          large
        />
        <Data
          key="rooms"
          label={'Salles'}
          value={residency.rooms.map((r) => r.label).join(' & ')}
          large
        />
      </div>
      <div className="data-section-row">
        <Data
          key="startDate"
          label={'Date de début'}
          value={formatDateString(residency.start_dt)}
          large
        />
        <Data
          key="endDate"
          label={'Date de fin'}
          value={formatDateString(residency.end_dt)}
          large
        />
        <Data key="email" label={'Adresse email'} value={residency.email} large />
      </div>
      <div className="data-section-row">
        <Data key="website" label={'Site web'} value={residency.website} large />
        <Data key="facebook_url" label={'Facebook'} value={residency.facebook_url} large />
        <Data key="instagram_url" label={'Instagram'} value={residency.instagram_url} large />
      </div>
      <div>
        <Data key="youtube_url" label={'Youtube'} value={residency.youtube_url} large />
      </div>
      <div className="data-section-row">
        <MultiLineData
          key="description"
          label={'Qui suis-je ?'}
          value={residency.description}
          large
        />
        <MultiLineData
          key="motivation"
          label={"Qu'est ce que je viens faire à Pasteur ?"}
          value={residency.motivation}
          large
        />
      </div>
    </Section>
  );
}

export default ResidencyInformation;
