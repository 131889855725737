import ResidencyListItem from './ResidencyListItem';

function ResidencyList({
  residencies,
  handleResidencyClick,
  residencyDetail,
  inResidencyDetail,
  pastResidency,
  inPopUp
}) {
  return (
    <>
      {residencies &&
        residencies.map((residency) => (
          <ResidencyListItem
            residency={residency}
            key={residency.id}
            handleResidencyClick={handleResidencyClick}
            residencyDetail={residencyDetail}
            inResidencyDetail={inResidencyDetail}
            pastResidency={pastResidency}
            inPopUp={inPopUp}
          />
        ))}
    </>
  );
}

export default ResidencyList;
