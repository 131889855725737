import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { publicFetch } from '../../utils/apiHelper';
import { validateEmail } from '../../utils/stringHelper';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import FormInput from '../../components/Backoffice/Form/FormInput';

function RecoverPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  const handleRecoverPassword = async () => {
    setLoading(true);
    await publicFetch(
      'POST',
      '/reset-password/',
      "Ce mail n'a pas été reconnu. Veuillez réessayer.",
      JSON.stringify({
        email: email
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
      } else {
        setShowSuccessMessage(true);
      }
    });
  };

  const emailIsValid = () => {
    return validateEmail(email);
  };

  const loginButton = (
    <span className="cursor-pointer hover:text-custom-orange" onClick={() => navigate('/login')}>
      Retour à la connexion
    </span>
  );

  return (
    <Layout>
      <div className="flex flex-col gap-y-16 ml-36 w-[500px] mb-8">
        <div className="flex flex-col gap-3">
          <span className="text-5xl font-semibold">Bienvenue</span>
          <span className="text-2xl font-normal">Récupérer votre mot de passe</span>
        </div>
        <div className="flex flex-col gap-6">
          <FormInput
            label={'Adresse mail'}
            required
            isValid={emailIsValid()}
            helpMessage={loginButton}>
            <InputText
              id="email"
              type="text"
              placeholder="Entrez votre adresse email"
              className="w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormInput>
        </div>
        {showSuccessMessage ? (
          <Message
            severity="success"
            text={`Un mail contenant un lien de réinitialisation du mot de passe vous a été envoyé`}
          />
        ) : (
          <Button
            label="Réinitialiser mon mot de passe"
            icon="pi pi-lock"
            className="btn"
            loading={loading}
            disabled={!emailIsValid()}
            onClick={handleRecoverPassword}
          />
        )}
      </div>
    </Layout>
  );
}

export default RecoverPassword;
