import React, { useState } from 'react';
import './Data.scss';

function MultiLineData({ label, value, large, bgColor, suffix }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    setCopied(true);
    // Copy to clipboard
    await navigator.clipboard.writeText(value);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };
  return (
    <div
      onClick={handleCopy}
      className={`flex flex-col gap-y-1 cursor-pointer data-container ${
        large ? 'w-[700px]' : 'w-[340px]'
      }`}>
      <div className="w-full flex flex-row justify-between items-center">
        <span className="text-slate-500 text-sm">{label}</span>
        <i
          className={`pi pi-copy copy-icon ${
            copied ? 'opacity-1 text-green-500' : 'opacity-0 text-slate-300'
          }`}
        />
      </div>
      <div className={`relative ${value ? '' : 'text-slate-400 italic font-extralight'}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: value ? value : '<p>Non renseigné</p>'
          }}></div>
      </div>
    </div>
  );
}

export default MultiLineData;
