import React from 'react';
import { AccountContextProvider } from './AccountContext';
import { NavigationContextProvider } from './NavigationContext';
import { ToastContextProvider } from './ToastContext';
import { PropertyContextProvider } from './PropertyContext';
import { RoomContextProvider } from './RoomContext';
import { DashboardContextProvider } from './DashboardContext';

const AppContexts = ({ children }) => (
  <AccountContextProvider>
    <ToastContextProvider>
      <PropertyContextProvider>
        <RoomContextProvider>
          <DashboardContextProvider>
            <NavigationContextProvider>{children}</NavigationContextProvider>
          </DashboardContextProvider>
        </RoomContextProvider>
      </PropertyContextProvider>
    </ToastContextProvider>
  </AccountContextProvider>
);

export default AppContexts;
