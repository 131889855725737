import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io';
import { CONSTANT } from '../../../utils/constants';
import Roof from '../../../assets/image/toit.webp';
import MainPlan from '../../../assets/image/plan-doodles.webp';
import BuildingFloor from './BuildingFloor';
import { IoIosArrowUp } from 'react-icons/io';
import { IoIosArrowDown } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import ClickOnPlan from '../../../assets/image/cursor-plan.webp';

function BuildingPlan({ handleResidencyClick, getResidency }) {
  const planRef = useRef(null);

  const [activeFloor, setActiveFloor] = useState('main-plan');

  const scroll = (ref) => {
    const floor = document.querySelector(`#${ref}`);
    floor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    const currentPlanRef = planRef.current;
    const handleScroll = () => {
      const scrollY = currentPlanRef.scrollTop;
      const floors = document.querySelectorAll('div[id]');
      floors.forEach((floor) => {
        const floorTop = floor.offsetTop - 100;
        const floorBottom = floorTop + floor.clientHeight;

        if (scrollY >= floorTop && scrollY < floorBottom) {
          setActiveFloor(floor.id);
        }
      });

      if (scrollY === 0) {
        setActiveFloor('main-plan');
      }
    };

    if (currentPlanRef) {
      currentPlanRef.addEventListener('scroll', handleScroll, {
        passive: true
      });
    }

    return () => {
      if (currentPlanRef) {
        currentPlanRef.removeEventListener('scroll', handleScroll, {
          passive: true
        });
      }
    };
  }, []);

  let activeFloorIndex = CONSTANT.buildingFloors.findIndex((floor) => floor.ref === activeFloor);

  const scrollUp = () => {
    const floor = CONSTANT.buildingFloors[Math.max(activeFloorIndex - 1, 0)].ref;
    scroll(floor);
  };

  const scrollDown = () => {
    if (activeFloor === 'root') {
      activeFloorIndex = 0;
    }

    const floor =
      CONSTANT.buildingFloors[Math.min(activeFloorIndex + 1, CONSTANT.buildingFloors.length)].ref;
    scroll(floor);
  };

  return (
    <div className="plan-wrapper">
      {activeFloor !== 'main-plan' && activeFloor !== 'root' && activeFloor !== 'roof' && (
        <>
          <button className="button-pill button-up desktop" onClick={() => scrollUp()}>
            <IoIosArrowRoundUp size={20} style={{ minHeight: 20, minWidth: 20 }} />
            {CONSTANT.buildingFloors[Math.max(activeFloorIndex - 1, 0)].label}
            <IoIosArrowRoundUp size={20} style={{ minHeight: 20, minWidth: 20 }} />
          </button>
          <button className=" button-up mobile" onClick={() => scrollUp()}>
            <div className="button-hitbox">
              <IoIosArrowUp size={20} />
            </div>
          </button>
        </>
      )}

      {activeFloor !== 'main-plan' && activeFloor !== 'root' && (
        <>
          <button
            className="button-pill button-up button-close desktop"
            onClick={() => scroll('main-plan')}>
            fermer
          </button>
          <button className=" button-down button-close mobile" onClick={() => scroll('main-plan')}>
            <div className="button-hitbox">
              <IoClose size={20} />
            </div>
          </button>
        </>
      )}

      <div className="plan-open-wrapper" ref={planRef}>
        <div className="plan-open">
          <img
            className="open-plan-button mobile"
            src={ClickOnPlan}
            alt={'Bouton pour ouvrir le plan'}
            onClick={() => {
              scroll('first');
            }}
          />
          <div
            className="floor main-plan"
            id="main-plan"
            onClick={() => {
              scroll('first');
            }}>
            <div className="image-wrapper">
              <img src={MainPlan} alt="plan du batiment en entier" className="main-plan-image" />
            </div>
          </div>
          <div className="floor" id="roof">
            <div className="image-wrapper">
              <img src={Roof} alt="plan du toit" className="plan-image" />
            </div>
          </div>

          {CONSTANT.buildingFloors.map(
            (floor, id) =>
              floor.image && (
                <div className="floor" id={floor.ref}>
                  <BuildingFloor
                    key={id}
                    getResidency={getResidency}
                    handleResidencyClick={handleResidencyClick}
                    image={floor.image}
                    nameOfFloor={floor.ref}
                  />
                </div>
              )
          )}
        </div>
      </div>
      {activeFloor !== 'main-plan' && activeFloor !== 'root' && (
        <div className="legend-container">
          <div
            className="room-circle"
            style={{
              width: 20,
              height: 20,
              backgroundColor: '#96c1fa'
            }}></div>
          <p>espaces utilisés au jour et filtres renseignés</p>
        </div>
      )}
      {activeFloor !== 'main-plan' && activeFloor !== 'root' && activeFloor !== 'ground' && (
        <>
          <button className="button-pill button-down desktop" onClick={() => scrollDown()}>
            <IoIosArrowRoundDown size={20} style={{ minHeight: 20, minWidth: 20 }} />
            {CONSTANT.buildingFloors[activeFloorIndex + 1].label}
            <IoIosArrowRoundDown size={20} style={{ minHeight: 20, minWidth: 20 }} />
          </button>
          <button className=" button-down mobile" onClick={() => scrollDown()}>
            <div className="button-hitbox">
              <IoIosArrowDown size={20} />
            </div>
          </button>
        </>
      )}
    </div>
  );
}

export default BuildingPlan;
