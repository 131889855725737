import React from 'react';
import { CONSTANT } from '../../../utils/constants';
import { useEffect, useRef, useState } from 'react';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io';
import BuildingRoom from './BuildingRoom';
import _ from 'underscore';
import { RxCross1 } from 'react-icons/rx';

function ResidencyDetailPlan({ residency, closing, handleClose }) {
  const planRef = useRef(null);

  const [activeFloorRD, setActiveFloorRD] = useState('');
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [filteredFloors, setFilteredFloors] = useState([]);

  const scroll = (ref) => {
    const floor = document.querySelector(`#${ref}`);
    floor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const noScroll = (ref) => {
    const floor = document.querySelector(`#${ref}`);
    floor.scrollIntoView({ block: 'center' });
  };

  useEffect(() => {
    const floor = residency.rooms[0].floor;
    noScroll(floor);
    setActiveFloorRD(floor);
    setFilteredRooms(
      residency.rooms.map((room) => CONSTANT.buildingPlanRooms.find((r) => r.slug === room.slug))
    );
    setFilteredFloors(
      _.uniq(
        residency.rooms.map((room) => CONSTANT.buildingFloors.find((f) => f.ref === room.floor))
      )
    );
  }, []);

  useEffect(() => {
    const floor = residency.rooms[0].floor;
    scroll(floor);
    setActiveFloorRD(floor);
    setFilteredRooms(
      residency.rooms.map((room) => CONSTANT.buildingPlanRooms.find((r) => r.slug === room.slug))
    );
  }, [residency]);

  let activeFloorIndexRD = CONSTANT.buildingFloors.findIndex(
    (floor) => floor.ref === activeFloorRD
  );

  const scrollUp = () => {
    const floor = CONSTANT.buildingFloors[Math.max(activeFloorIndexRD - 1, 0)].ref;
    scroll(floor);
    setActiveFloorRD(floor);
  };

  const scrollDown = () => {
    if (activeFloorRD === 'third') {
      activeFloorIndexRD = 2;
    }

    const floor =
      CONSTANT.buildingFloors[Math.min(activeFloorIndexRD + 1, CONSTANT.buildingFloors.length)].ref;
    scroll(floor);
    setActiveFloorRD(floor);
  };

  const isDifferentFloor = _.uniq(filteredRooms.map((room) => room.floor)).length > 1;

  return (
    <>
      {window.innerWidth > 950 ? (
        <div className={`residency-plan-wrapper ${closing && 'residency-closing'}`}>
          <RxCross1 size={50} onClick={handleClose} className="cross-plan" />
          {activeFloorRD !== 'third' && isDifferentFloor && (
            <button className="button-pill button-up desktop" onClick={() => scrollUp()}>
              <IoIosArrowRoundUp size={20} style={{ minHeight: 20, minWidth: 20 }} />
              <IoIosArrowRoundUp size={20} style={{ minHeight: 20, minWidth: 20 }} />
            </button>
          )}
          <div className="plan-open-wrapper">
            <div className="plan-open" ref={planRef}>
              {CONSTANT.buildingFloors.map(
                (floor, id) =>
                  floor.image && (
                    <div className="floor" id={floor.ref} key={id}>
                      <div className="image-wrapper">
                        <img src={floor.image} alt="plan de l'étage" className="plan-image" />

                        {filteredRooms.map(
                          (room, index) =>
                            room.floor === floor.ref && (
                              <BuildingRoom room={room} key={index} residency={residency} />
                            )
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>

          {activeFloorRD !== 'ground' && isDifferentFloor && (
            <button className="button-pill button-down desktop" onClick={() => scrollDown()}>
              <IoIosArrowRoundDown size={20} style={{ minHeight: 20, minWidth: 20 }} />
              <IoIosArrowRoundDown size={20} style={{ minHeight: 20, minWidth: 20 }} />
            </button>
          )}
        </div>
      ) : (
        <div className={`residency-plan-wrapper`}>
          {filteredFloors.map(
            (floor, id) =>
              floor.image && (
                <div className="floor" key={id}>
                  <div className="image-wrapper">
                    <img src={floor.image} alt="plan de l'étage" className="plan-image" />

                    {filteredRooms.map(
                      (room, index) =>
                        room.floor === floor.ref && (
                          <BuildingRoom room={room} key={index} residency={residency} />
                        )
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </>
  );
}

export default ResidencyDetailPlan;
