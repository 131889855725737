import moment from 'moment';
import { useState } from 'react';
import './ResidencyListItem.scss';
import { useMemo } from 'react';
import Sky from '../../../assets/image/sky-small.webp';

function ResidencyListItem({
  residency,
  handleResidencyClick,
  residencyDetail,
  inResidencyDetail,
  pastResidency,
  inPopUp
}) {
  const [hover, setHover] = useState(false);
  const today = moment();

  const isOpen = () => {
    return residencyDetail && residencyDetail.slug === residency.slug;
  };

  const handleMouseOver = (e) => {
    !inResidencyDetail && !inPopUp && setHover(true);
  };

  const handleMouseMove = (e) => {
    const image = document.querySelector('.list-item img');
    if (image) {
      image.style.top = e.clientY + 'px';
      image.style.left = e.clientX + 'px';
    }
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  //display 'Hôte' if it's a long residency, 'RDV public' if it's an event, and nothing if it's 'la conciergerie' or 'edulab'

  const typeOfResidency = useMemo(() => {
    if (residency.type === 'permanent') return 'Hôte permanent -';

    if (residency.type === 'event') return 'RDV publics -';

    return 'Hôte -';
  }, []);

  // format of date according to the type of residency and if it hasn't started yet

  const formatDate = useMemo(() => {
    if (residency.type === 'permanent') {
      return ``;
    }
    if (new Date(residency.start_dt).toDateString() === new Date(residency.end_dt).toDateString()) {
      return `- le ${moment(residency.start_dt).format('DD/MM/YYYY')}`;
    } else {
      return `- du ${moment(residency.start_dt).format('DD/MM/YYYY')} au 
        ${moment(residency.end_dt).format('DD/MM/YYYY')}`;
    }
  }, []);

  // get the number of event of a residency

  const numberOfEvent = useMemo(() => {
    if (residency.type === 'event') return undefined;
    return residency.events?.filter((event) => moment(event.end_dt).isAfter(today, 'day')).length;
  });

  return (
    <div
      onClick={() => {
        !pastResidency && handleResidencyClick(residency);
      }}
      onMouseOver={handleMouseOver}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      className={`list-item ${inResidencyDetail && 'list-residency-detail'} ${isOpen() ? 'open' : pastResidency ? 'past-residency' : 'close'}`}>
      <div className="list-item-title">{residency.label}</div>
      <div className="list-item-tags">
        {`${typeOfResidency} ${residency.rooms.map((room) => `${room.label}`)} ${formatDate}  `}
      </div>
      <div className="list-item-rdv">
        {numberOfEvent > 0 && `${numberOfEvent} rendez-vous publics à venir ou en cours`}
      </div>
      {hover && (
        <img
          src={residency.image ? residency.image.file : Sky}
          alt={residency.image ? residency.label : 'ciel'}
        />
      )}
    </div>
  );
}

export default ResidencyListItem;
