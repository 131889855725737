import React from 'react';
import CustomIcon from '../CustomIcon';

function NavbarIconItem({ icon, onClick, isActive }) {
  return (
    <div
      onClick={onClick}
      className={`w-16 h-full flex items-center justify-center transition-colors ${
        isActive
          ? 'opacity-100 text-yellow-500'
          : 'opacity-80 text-slate-50 hover:opacity-100 hover:bg-slate-900 hover:cursor-pointer'
      }`}>
      <CustomIcon icon={icon} className={'h-6 w-6'} />
    </div>
  );
}

export default NavbarIconItem;
