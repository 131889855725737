import './Page404.scss';
import Menu from '../../../components/Frontoffice/Menu/Menu';
import Footer from '../../../components/Frontoffice/Footer/Footer';

function Page404() {
  return (
    <div className="frontoffice-css-scope">
      <div className="page-404">
        <Menu />
        <div className="page-404-container">
          <div>hôtel</div>
          <div className="error-404">404</div>
          <div>page non trouvée</div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Page404;
