import { NavLink } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="menu-item">
        <div className="menu-title">
          <NavLink to="/mentions-legales" className={({ isActive }) => (isActive ? 'active' : '')}>
            MENTIONS LÉGALES ET CRÉDITS
          </NavLink>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
