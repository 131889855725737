import { Calendar } from 'primereact/calendar';
import { useState, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { formatDateString } from '../../../utils/stringHelper';
import { IoIosArrowRoundDown } from 'react-icons/io';

function CalendarCustom({ date, setDate, dateAsString }) {
  const today = moment();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const calendarPillRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (calendarPillRef.current && !calendarPillRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const selectDate = (date) => {
    setDate(date);
    setIsOpen(false);
  };

  return (
    <div ref={calendarPillRef}>
      <div onClick={() => setIsOpen(!isOpen)} className="yellow-bloc calendar-pill">
        {dateAsString}
      </div>
      <div onClick={() => setIsOpen(!isOpen)} className="calendar-pill-mobile">
        {dateAsString}
        <IoIosArrowRoundDown size={20} />
      </div>
      {isOpen && (
        <Calendar
          value={date}
          onChange={(e) => selectDate(e.value)}
          dateFormat="DD/MM/YYYY"
          locale="fr"
          inline
          className="calendar"
        />
      )}
    </div>
  );
}

export default CalendarCustom;
