import { Link } from 'react-router-dom';

function Pill({ label, isActive, value }) {
  return (
    <Link
      to={`/economie-contributive?y=${value ? value : label}`}
      className={`button year ${isActive ? 'selected' : ''}`}>
      {label}
    </Link>
  );
}

export default Pill;
