import React, { createContext, useContext, useState, useEffect } from 'react';
import { getNavigation, saveNavigation } from '../utils/navigationHelper';

const NavigationContext = createContext(null);

const initial = {
  activeApp: null,
  activeResidency: {
    label: '',
    id: null
  },
  recentResidencies: []
};

export const NavigationContextProvider = ({ children }) => {
  const [navigationContext, setNavigationContext] = useState(getNavigation() || initial);

  useEffect(() => {
    if (navigationContext && navigationContext.activeApp !== null) {
      saveNavigation(navigationContext);
    }
  }, [navigationContext]);

  const manageRecentResidencies = (targetResidency) => {
    const previousActiveResidency = navigationContext.activeResidency;
    const previousRecentResidencies = navigationContext.recentResidencies || [];

    let recentResidencies = previousRecentResidencies.filter((p) => p.id !== targetResidency.id);
    if (
      navigationContext.activeResidency?.id &&
      previousActiveResidency &&
      previousActiveResidency.id != targetResidency.id
    ) {
      recentResidencies.unshift(navigationContext.activeResidency);
    }
    return recentResidencies.slice(0, 7);
  };

  const openResidencyDetail = (targetResidency) => {
    const recentResidencies = manageRecentResidencies(targetResidency);
    setNavigationContext({
      ...navigationContext,
      recentResidencies,
      activeApp: 'residency',
      activeResidency: {
        id: targetResidency.id,
        label: targetResidency.label
      }
    });
  };

  return (
    <NavigationContext.Provider
      value={{ navigationContext, setNavigationContext, openResidencyDetail }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  return useContext(NavigationContext);
};
