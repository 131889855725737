import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getConstant } from '../../../utils/constants';
import Tag from '../../../components/Backoffice/Display/Tag';
import { formatDateFromNowString, formatDateString } from '../../../utils/stringHelper';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import DashboardFilters from './DashboardFilters';

function DashboardDatatable({ data, loading, onResidencySelect }) {
  const { dashboardContext, setDashboardOptions } = useDashboardContext();

  const onPage = (event) => {
    setDashboardOptions({ page: event.page, offset: event.first, rows: event.rows });
  };

  return (
    <div className="flex flex-col gap-y-3">
      {<DashboardFilters />}
      <DataTable
        id={'dashboard-table-with-filters'}
        value={data.residencyList}
        removableSort
        lazy
        scrollable={true}
        loading={loading}
        onRowClick={(event) => onResidencySelect(event.data)}
        size="small"
        paginator
        rows={dashboardContext.options.rows}
        first={dashboardContext.options.offset}
        filters={dashboardContext.options.filters}
        totalRecords={data.totalRows}
        rowsPerPageOptions={[5, 20, 50, 100, 200]}
        onPage={onPage}
        selectionMode="single">
        <Column
          frozen
          bodyClassName="overflow-hidden text-ellipsis whitespace-nowrap max-w-[400px] font-semibold"
          field="label"
          header="Nom"></Column>
        <Column
          frozen
          bodyClassName="overflow-hidden text-ellipsis whitespace-nowrap max-w-[250px]"
          field="project_developer"
          header="Porteur.euse de projet"></Column>
        <Column
          field="type"
          header="Type"
          body={(rowData) => (
            <Tag tag={getConstant('backoffice.residency.typeList', rowData.type)} />
          )}></Column>
        <Column
          field="state"
          header="État"
          body={(rowData) => (
            <Tag tag={getConstant('backoffice.residency.stateList', rowData.state)} />
          )}></Column>
        <Column
          field="rooms"
          bodyClassName="overflow-hidden text-ellipsis whitespace-nowrap max-w-[350px]"
          header="Salles"
          body={(rowData) => rowData.rooms.map((r) => r.label).join(' & ')}></Column>
        <Column
          body={(rowData) => formatDateString(rowData.start_dt)}
          field={'start_dt'}
          header="Date de début"
          dataType="date"></Column>
        <Column
          body={(rowData) => formatDateString(rowData.end_dt)}
          field={'end_dt'}
          header="Date de fin"
          dataType="date"></Column>
        <Column
          body={(rowData) => formatDateFromNowString(rowData.custom_user?.last_signin_dt)}
          field={'custom_user?.last_signin_dt'}
          header="Dernière connexion"
          dataType="date"></Column>
      </DataTable>
    </div>
  );
}

export default DashboardDatatable;
