import React from 'react';
import { CONSTANT } from '../../../utils/constants';
import BuildingRoom from './BuildingRoom';

function BuildingFloor({ getResidency, handleResidencyClick, image, nameOfFloor }) {
  return (
    <div className="image-wrapper">
      {CONSTANT.buildingPlanRooms.map(
        (room, index) =>
          room.floor === nameOfFloor && (
            <BuildingRoom
              room={room}
              handleResidencyClick={handleResidencyClick}
              residency={getResidency(room)}
              key={index}
              isMainPlan
            />
          )
      )}
      <img src={image} alt="plan de l'étage" className="plan-image" />
    </div>
  );
}

export default BuildingFloor;
