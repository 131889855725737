import { FaArrowDownLong } from 'react-icons/fa6';
import { useEffect, useState } from 'react';

function AccordionItem({ content }) {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
  }, [content]);

  return (
    <div>
      <div onClick={() => setIsOpen(!isOpen)} className="button element-title">
        {content.title}
        <FaArrowDownLong size={20} style={{ transform: isOpen ? 'rotate(180deg)' : null }} />
      </div>
      <div className="accordion-bottom" style={{ maxHeight: isOpen ? 'fit-content' : 0 }}>
        {isOpen ? (
          <>
            <p dangerouslySetInnerHTML={{ __html: content.legend }} className="accordion-legend" />
            <p dangerouslySetInnerHTML={{ __html: content.content }} />
            <div className="images-wrapper">
              {content.images.map((image, index) => (
                <img src={image.name} alt={image.alt} key={index} />
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default AccordionItem;
