import moment from 'moment';
import React, { useMemo, useEffect, useRef } from 'react';
import ResidencyTag from '../../../pages/public/ResidencyPage/ResidencyTag';
import './ResidencyDetail.scss';
import ResidencyListItem from '../ResidencyListItem/ResidencyListItem';
import ResidencyList from '../ResidencyListItem/ResidencyList';
import Sky from '../../../assets/image/sky-small.webp';
import ResidencyDetailPlan from '../../../pages/public/ResidencyPage/ResidencyDetailPlan';
import { RxCross1 } from 'react-icons/rx';
import { TbWorld } from 'react-icons/tb';
import { FiYoutube } from 'react-icons/fi';
import { FaInstagram } from 'react-icons/fa6';
import { FiFacebook } from 'react-icons/fi';
import { size } from 'underscore';

function ResidencyDetail({
  residencyDetail,
  closing,
  handleResidencyClick,
  handleClose,
  inMapPage,
  inArchivePage
}) {
  const residencyLabel = residencyDetail.label;
  const today = moment();
  const detailRef = useRef(null);

  useEffect(() => {
    detailRef.current.scrollTo(0, 0);
  }, [residencyDetail]);

  // Returns undefined if residency is an event
  const nextEventList = useMemo(() => {
    if (residencyDetail.type === 'event') return undefined;
    return residencyDetail.events?.filter((event) => moment(event.end_dt).isAfter(today, 'day'));
  }, [residencyDetail]);

  const pastEventList = useMemo(() => {
    if (residencyDetail.type === 'event') return undefined;
    return residencyDetail.events?.filter((event) => moment(event.end_dt).isBefore(today, 'day'));
  }, [residencyDetail]);

  // returns address if the residency is over, returns a certain type of date according to the type of residency
  const formatDate = useMemo(() => {
    if (inMapPage) return residencyDetail.location.name;
    if (residencyDetail.type === 'permanent') {
      return ``;
    }
    if (
      new Date(residencyDetail.start_dt).toDateString() ===
        new Date(residencyDetail.end_dt).toDateString() &&
      residencyDetail.type === 'event' &&
      residencyDetail.start_dt !== residencyDetail.end_dt
    ) {
      return `le ${moment(residencyDetail.start_dt).format('ll')} de ${moment(residencyDetail.start_dt).format('HH:mm')} à ${moment(residencyDetail.end_dt).format('HH:mm')}`;
    }
    if (
      new Date(residencyDetail.start_dt).toDateString() ===
      new Date(residencyDetail.end_dt).toDateString()
    ) {
      return `le ${moment(residencyDetail.start_dt).format('ll')}`;
    } else {
      return `du ${moment(residencyDetail.start_dt).format('ll')} au 
        ${moment(residencyDetail.end_dt).format('ll')}`;
    }
  }, [residencyDetail]);

  return (
    <div
      className={`residency-detail-wrapper ${closing && 'closing'} ${inMapPage && 'residency-detail-wrapper-map'}`}>
      <div className="bloc-scrolling">
        <div className={`content-wrapper ${inMapPage && 'content-map'}`} ref={detailRef}>
          <div className="head-residency-detail mobile">
            <h1>
              {!inMapPage
                ? residencyLabel.toLowerCase()
                : residencyDetail.project_developer
                  ? residencyDetail.project_developer
                  : residencyLabel.toLowerCase()}
            </h1>
            <RxCross1 size={50} onClick={handleClose} style={{ cursor: 'pointer', zIndex: 1000 }} />
          </div>

          {inMapPage && (
            <div className="head-residency-detail desktop">
              <RxCross1 size={50} onClick={handleClose} className="cross-plan" />
            </div>
          )}
          <div className="head-residency-detail desktop">
            <h1>
              {!inMapPage
                ? residencyLabel.toLowerCase()
                : residencyDetail.project_developer
                  ? residencyDetail.project_developer
                  : residencyLabel.toLowerCase()}
            </h1>
          </div>

          <div className="legend-residency">
            {residencyDetail.project_developer && !inMapPage && (
              <p>par {residencyDetail.project_developer}</p>
            )}
            <p>{formatDate}</p>
            {!inMapPage && (
              <ul>
                {residencyDetail.rooms?.map((room) => (
                  <li key={room.id}>{room.label}</li>
                ))}
              </ul>
            )}
          </div>

          <div className="tags-wrapper">
            {residencyDetail.properties.map((property) => (
              <ResidencyTag property={property} key={property.id} />
            ))}
          </div>

          {residencyDetail.type === 'event' && residencyDetail.events.length > 0 && !inMapPage && (
            <div className="events">
              <p>organisé par :</p>
              <div className="list-item-wrapper">
                {residencyDetail.events.map((event) => (
                  <ResidencyListItem
                    key={event.id}
                    residency={event}
                    handleResidencyClick={handleResidencyClick}
                    residencyDetail={residencyDetail}
                    inResidencyDetail
                  />
                ))}
              </div>
            </div>
          )}
          <p
            className="description-residency"
            dangerouslySetInnerHTML={{
              __html: residencyDetail.description
            }}></p>

          {!inMapPage && (
            <p
              className="description-residency"
              dangerouslySetInnerHTML={{
                __html: residencyDetail.motivation
              }}></p>
          )}
          {nextEventList && nextEventList.length > 0 && (
            <div className="events">
              <p>RDV publics à venir ou en cours :</p>
              <div className="list-item-wrapper">
                <ResidencyList
                  residencies={nextEventList}
                  handleResidencyClick={handleResidencyClick}
                  residencyDetail={residencyDetail}
                  inResidencyDetail
                />
              </div>
            </div>
          )}
          <div className="social-network">
            {residencyDetail.website && (
              <a
                href={residencyDetail.website}
                target="_blank"
                rel="noreferrer"
                className="social-network-item">
                <TbWorld size={30} /> <p>Site internet</p>
              </a>
            )}
            {residencyDetail.instagram_url && (
              <a href={residencyDetail.instagram_url} target="_blank" rel="noreferrer">
                <FaInstagram size={30} />
              </a>
            )}
            {residencyDetail.facebook_url && (
              <a href={residencyDetail.facebook_url} target="_blank" rel="noreferrer">
                <FiFacebook size={30} />
              </a>
            )}
            {residencyDetail.youtube_url && (
              <a href={residencyDetail.youtube_url} target="_blank" rel="noreferrer">
                <FiYoutube size={30} />
              </a>
            )}
          </div>

          {residencyDetail.image ? (
            <img
              src={residencyDetail.image.file}
              alt={residencyDetail.image.name}
              style={{ width: '100%', height: 'auto' }}
            />
          ) : !inMapPage ? (
            <img
              src={Sky}
              alt="ciel"
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
            />
          ) : null}

          {!inMapPage && pastEventList && pastEventList.length > 0 && (
            <div className="events">
              <p>RDV publics passés :</p>
              <div className="list-item-wrapper">
                <ResidencyList
                  residencies={pastEventList}
                  handleResidencyClick={handleResidencyClick}
                  residencyDetail={residencyDetail}
                  inResidencyDetail
                  pastResidency={inMapPage}
                />
              </div>
            </div>
          )}
        </div>
        {window.innerWidth < 950 && !inMapPage && !inArchivePage && (
          <div className="residency-plan-mobile-wrapper">
            <ResidencyDetailPlan
              residency={residencyDetail}
              closing={closing}
              handleClose={handleClose}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ResidencyDetail;
