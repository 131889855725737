import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { privateFetch } from '../../../utils/apiHelper';
import CustomIcon from '../../../components/Backoffice/CustomIcon';
import Navbar from '../../../components/Backoffice/Navbar/Navbar';
import DashboardDatatable from './DashboardDatatable';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import ManageResidency from '../Residency/ManageResidency';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import slugify from 'react-slugify';
import { useDebouncedCallback } from 'use-debounce';

function DashboardPage() {
  const { dashboardContext } = useDashboardContext();
  const { openResidencyDetail } = useNavigationContext();
  const [data, setData] = useState({
    residencyList: [],
    totalRows: 0
  });
  const [residencyPanelIsOpen, setResidencyPanelOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchResidencyList();
  }, []);

  useEffect(() => {
    refreshResidencyList();
  }, [dashboardContext]);

  const refreshResidencyList = useDebouncedCallback(() => {
    fetchResidencyList();
  }, 500);

  const fetchResidencyList = async () => {
    await privateFetch(
      'GET',
      `/residency/?rows=${dashboardContext.options.rows}&page=${
        dashboardContext.options.page
      }&offset=${dashboardContext.options.offset}&query=${slugify(
        dashboardContext.filters.query
      )}&state=${dashboardContext.filters.stateList}&type=${dashboardContext.filters.typeList}`
    ).then((res) => {
      if (res) {
        setData({
          residencyList: res.rows,
          totalRows: res.total_rows
        });
      }
    });
  };

  const createResidency = async (residencyForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/residency/`,
      'Le séjour a bien été ajouté',
      JSON.stringify(residencyForm)
    ).then((res) => {
      fetchResidencyList();
      setLoading(false);
      setResidencyPanelOpen(false);
      openResidencyDetail({ id: res.id, label: res.label });
    });
  };

  const selectResidency = (targetResidency) => {
    openResidencyDetail(targetResidency);
  };

  return (
    <div className={`h-screen w-screen bg-white flex flex-col`}>
      <Navbar />
      <div className="page-wrapper">
        <div className="flex flex-col gap-y-3">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row h-full gap-x-3 items-center text-slate-700 p-2">
              <CustomIcon icon={'residency'} className={'h-6 w-6'} />
              <span className="font-medium text-nowrap">{`${data?.totalRows} séjours`}</span>
            </div>

            <Button
              key="additionnal"
              size="small"
              outlined
              rounded
              label={'Ajouter un séjour'}
              iconPos="left"
              icon={`pi pi-plus`}
              onClick={() => setResidencyPanelOpen(true)}
              className="text-slate-800"
            />
          </div>
          <DashboardDatatable data={data} onResidencySelect={selectResidency} />
        </div>
      </div>

      <ManageResidency
        loading={loading}
        isOpen={residencyPanelIsOpen}
        handleClose={() => {
          setResidencyPanelOpen(false);
        }}
        createResidency={createResidency}
      />
    </div>
  );
}

export default DashboardPage;
