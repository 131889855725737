import Menu from '../../../components/Frontoffice/Menu/Menu';
import AccordionItem from '../../../components/Frontoffice/Accordion/AccordionItem';
import Pill from '../../../components/Frontoffice/Pill/Pill';
import Footer from '../../../components/Frontoffice/Footer/Footer';
import './EconomyPage.scss';
import { DATA } from '../../../utils/data';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

function EconomyPage() {
  const [yearSelected, setYearSelected] = useState(null);
  const location = useLocation();

  const ecoItem = DATA.economyItems.find((item) => item.year === yearSelected);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const year = searchParams.get('y');
    setYearSelected(year || DATA.economyItems[0].year);
  }, [location.search]);

  return (
    <div className="frontoffice-css-scope">
      <Menu />
      <div className="page-wrapper ">
        <div className="economy-page">
          <div className="economyyears-wrapper">
            <h1>la réciprocité des hôtes, une économie contributive</h1>
            <div className="economyyears-content">
              <p>La réciprocité année par année:</p>
              <div className="pills-wrapper">
                {DATA.economyItems.map((ei, index) => (
                  <Pill label={ei.year} key={index} isActive={yearSelected === ei.year} />
                ))}
              </div>
              <br />
              <p>
                L’économie contributive ce sont toutes les petites et grandes choses qui sont
                pensées, créées, déposées, apportées au quotidien du lieu. C’est le temps passé à
                Pasteur à vivre le lieu, accueillir des visiteureuses, transmettre l’histoire du
                projet, documenter son passage, participer à sa gouvernance, et prendre soin du
                bâtiment et des ses occupant·es. <br />
                <br /> A Pasteur, tout est important et chaque petite action compte.
              </p>
            </div>
          </div>

          {yearSelected && (
            <div className="economycontent-wrapper">
              <h1>{ecoItem.year}</h1>
              <p className="intro">{ecoItem.legend}</p>

              <div className="accordion-wrapper">
                {ecoItem.economyContent.map((ec, index) => (
                  <AccordionItem content={ec} key={index} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EconomyPage;
