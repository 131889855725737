import Menu from '../../../components/Frontoffice/Menu/Menu';
import Footer from '../../../components/Frontoffice/Footer/Footer';
import './LegalPage.scss';

function LegalPage() {
  return (
    <div className="frontoffice-css-scope">
      <Menu />
      <div className="page-wrapper">
        <div className="legal-credits-container">
          <div className="legal-container">
            <h1>mentions légales</h1>
            <p>
              Le site le-registre.hotelpasteur.fr est édité par l’Hôtel Pasteur, association loi
              1901 dont le siège social est situé au 2 place Pasteur, 35000 Rennes. Le contenu du
              site est protégé par les lois françaises en vigueur sur la propriété intellectuelle.
              L’ensemble des éléments le composant sont la propriété exclusive de leurs auteurs.
            </p>
            <br />
            <p>
              Le présent site est hébergé par la société OVH SAS, dont le siège social est situé au
              2 rue Kellermann, 59100 Roubaix.
            </p>
          </div>
          <div className="credits-container">
            <h1>crédits</h1>
            <p>
              L’identité visuelle de l'hôtel Pasteur a été élaborée par{' '}
              <a href="https://gregoireroma.net/" target="_blank" rel="noreferrer">
                Grégoire Romanet
              </a>{' '}
              et{' '}
              <a href="https://www.geoffroypithon.net" target="_blank" rel="noreferrer">
                Geoffroy Pithon
              </a>
              . Cette seconde version du Registre a été conçue et développée par le{' '}
              <a href="https://collectifnode.fr/" target="_blank" rel="noreferrer">
                Collectif Node
              </a>
              .
            </p>
            <br />
            <p>
              La première version fut réalisée par Marin Esnault et Quentin Ohrant avec{' '}
              <a href="https://idlv.co/" target="_blank" rel="noreferrer">
                le collectif Indien.ne.s dans la Ville
              </a>
              . La société Eegle et La société Zenika furent partenaires du projet. Le plan et
              l'axonométrie de l’Hôtel Pasteur ont été réalisés par Quentin Ohrant et Perrine Cariou
              dans le cadre de la réalisation de la première version du Registre.
            </p>
            <br />
            <p>
              Polices de caractères utilisées : Pasteur par{' '}
              <a href="https://gregoireroma.net/" target="_blank" rel="noreferrer">
                Grégoire Romanet
              </a>{' '}
              et{'  '}
              <a href="https://www.geoffroypithon.net" target="_blank" rel="noreferrer">
                Geoffroy Pithon
              </a>
              . GT Cinetype par{' '}
              <a
                href="https://www.grillitype.com/typeface/gt-cinetype"
                target="_blank"
                rel="noreferrer">
                Grilly Type
              </a>
              .
            </p>
            <br />
            <p>
              Ce projet a reçu le soutien de l’appel à projets créativité croisée – Rennes
              Métropole.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LegalPage;
