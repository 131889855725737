import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import React, { useMemo } from 'react';

function DashboardSearch({ value, handleSearch }) {
  const isActive = useMemo(() => value && value.length, [value]);

  return (
    <IconField iconPosition="left">
      <InputIcon className="pi pi-search ml-0.5"> </InputIcon>
      <InputText
        placeholder="Nom de séjour, porteur.euse de projet"
        className={`p-inputtext-sm rounded-full w-[350px] ${isActive && 'active-custom-dropdown'}`}
        onChange={(e) => handleSearch(e.target.value)}
        value={value}
      />
    </IconField>
  );
}

export default DashboardSearch;
