// PrimeReact
import { PrimeReactProvider, locale, addLocale } from 'primereact/api';
import { HashRouter } from 'react-router-dom';
import AppContexts from './contexts/Contexts';
import Router from './Router';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { localeJson } from './assets/theme/fr';
import moment from 'moment';
import 'moment/locale/fr';
import './assets/theme/custom-theme/theme.scss';
import './Backoffice.scss';
import './App.scss';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

// Primereact Locale
addLocale('fr', localeJson.fr);
locale('fr');

// Moment locale
moment.updateLocale('fr', {
  months:
    'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
      '_'
    ),
  monthsShort:
    'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
      '_'
    ),
  weekdays: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
  weekdaysShort: 'Dim_Lun_Mar_Mer_Jeu_Ven_Sam'.split('_')
});

function App() {
  return (
    <PrimeReactProvider>
      <AppContexts>
        <HashRouter>
          <ConfirmDialog />
          <Router />
        </HashRouter>
      </AppContexts>
    </PrimeReactProvider>
  );
}

export default App;
