import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import MarkerMap from '../../../assets/icon/marker-map.webp';
import ChangeView from './ChangeView';

function Map({ latitude, longitude }) {
  const customIcon = new Icon({
    iconUrl: MarkerMap,
    iconSize: [32, 32]
  });

  return (
    <MapContainer
      center={[latitude ? latitude : 48.11448, longitude ? longitude : -1.660525]}
      zoom={12}>
      <ChangeView
        center={[latitude ? latitude : 48.11448, longitude ? longitude : -1.660525]}
        zoom={12}
      />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {latitude && longitude && (
        <Marker position={[latitude, longitude]} icon={customIcon} style={{ zIndex: 1 }}></Marker>
      )}
    </MapContainer>
  );
}

export default Map;
