import React from 'react';
import { ReactComponent as User } from '../../assets/icon/user.svg';
import { ReactComponent as Logo } from '../../assets/icon/logo.svg';
import { ReactComponent as Residency } from '../../assets/icon/residency.svg';
import { ReactComponent as Dashboard } from '../../assets/icon/dashboard.svg';
import { ReactComponent as Admin } from '../../assets/icon/admin.svg';
import { ReactComponent as Logout } from '../../assets/icon/logout.svg';
import { ReactComponent as Chevron } from '../../assets/icon/chevron.svg';
import { ReactComponent as Website } from '../../assets/icon/website.svg';

function CustomIcon(props) {
  switch (props.icon) {
    case 'user':
      return <User {...props} />;
    case 'logo':
      return <Logo {...props} />;
    case 'residency':
      return <Residency {...props} />;
    case 'admin':
      return <Admin {...props} />;
    case 'dashboard':
      return <Dashboard {...props} />;
    case 'logout':
      return <Logout {...props} />;
    case 'chevron':
      return <Chevron {...props} />;
    case 'website':
      return <Website {...props} />;
    default:
      return null;
  }
}

export default CustomIcon;
