import React, { useState } from 'react';
import MenuItem from './MenuItem';
import { CONSTANT } from '../../../utils/constants';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Link } from 'react-router-dom';

const loginMenuItem = {
  title: 'SE CONNECTER',
  link: '/login'
};

function Menu() {
  const [menuMobileIsOpen, setMenuMobileIsOpen] = useState(false);

  return (
    <div>
      <nav>
        <div className="menu-wrapper-desktop">
          {CONSTANT.menuItems.map((menuItem) => (
            <MenuItem
              menuItem={menuItem}
              key={menuItem.id}
              setMenuMobileIsOpen={setMenuMobileIsOpen}
            />
          ))}
        </div>
        <div className="menu-wrapper-desktop">
          <MenuItem menuItem={loginMenuItem} setMenuMobileIsOpen={setMenuMobileIsOpen} />

          <a
            className="lien-hp"
            href="https://www.hotelpasteur.fr/"
            target="_blank"
            rel="noreferrer">
            Hôtel Pasteur <IoIosArrowRoundForward size={20} />
          </a>
        </div>
        <div className="menu-wrapper-mobile">
          <div className="menu-label" onClick={() => setMenuMobileIsOpen(!menuMobileIsOpen)}>
            {menuMobileIsOpen ? 'FERMER' : 'MENU'}
          </div>
        </div>
      </nav>
      {menuMobileIsOpen && (
        <div className="menu-mobile">
          <ol className="menu-items ">
            {CONSTANT.menuItems.map((menuItem, index) => (
              <li>
                {index + 1}
                <MenuItem
                  menuItem={menuItem}
                  key={menuItem.id}
                  setMenuMobileIsOpen={setMenuMobileIsOpen}
                />
              </li>
            ))}
          </ol>

          <div className="menu-footer">
            <a
              className="lien-hp"
              href="https://www.hotelpasteur.fr/"
              target="_blank"
              rel="noreferrer">
              Hôtel Pasteur <IoIosArrowRoundForward size={20} />
            </a>

            <Link to="/mentions-legales" onClick={() => setMenuMobileIsOpen(!menuMobileIsOpen)}>
              MENTIONS LÉGALES ET CRÉDITS
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Menu;
