import { useEffect, useState, useMemo, useRef } from 'react';
import Menu from '../../../components/Frontoffice/Menu/Menu';
import Footer from '../../../components/Frontoffice/Footer/Footer';
import DropDown from '../../../components/Frontoffice/DropDown/DropDown';
import ResidencyList from '../../../components/Frontoffice/ResidencyListItem/ResidencyList';
import ResidencyDetail from '../../../components/Frontoffice/ResidencyDetail/ResidencyDetail';
import { publicFetch } from '../../../utils/apiHelper';
import './ResidencyPage.scss';
import { CONSTANT, getConstant } from '../../../utils/constants';
import CalendarCustom from './CalendarCustom';
import moment from 'moment';
import { IoIosArrowRoundDown } from 'react-icons/io';
import { usePropertyContext } from '../../../contexts/PropertyContext';
import BuildingPlan from './BuildingPlan';
import ResidencyDetailPlan from './ResidencyDetailPlan';
import ResidencyPopUp from './ResidencyPopUp';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { formatDateString } from '../../../utils/stringHelper';
import Glossary from './Glossary';
import { ProgressSpinner } from 'primereact/progressspinner';

function ResidencyPage() {
  const { propertyContext } = usePropertyContext();
  const [residencyList, setResidencyList] = useState([]);
  const [residencyDetail, setResidencyDetail] = useState(undefined);
  const [date, setDate] = useState('');
  const [selectedType, setSelectedType] = useState(CONSTANT.residencyFilterOptions[0]);
  const [closing, setClosing] = useState(false);
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [topListHeight, setTopListHeight] = useState(0);
  const toplistref = useRef(null);
  const listref = useRef(null);
  const residencyref = useRef(null);
  const [residenciesPopup, setResidenciesPopup] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const today = moment();
  const [glossaryOpen, setGlossaryOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchResidencyList = async () => {
    const dateUrl = moment(date).format('YYYY-MM-DD');
    const residencyType = selectedType.value;
    const tagUrl = selectedTag ? selectedTag.slug : '';
    setLoading(true);

    await publicFetch(
      'GET',
      `/residency/?d=${dateUrl}&types=${residencyType}${`&p=${tagUrl}`}`
    ).then((response) => {
      setResidencyList(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (date) {
      fetchResidencyList();
      setResidencyDetail(undefined);
    }
  }, [date, selectedType, selectedTag]);

  const fetchResidencyDetail = async (residencySlug) => {
    await publicFetch('GET', `/residency/${residencySlug}`).then((response) => {
      setResidencyDetail(response);
      setResidenciesPopup([]);
    });
  };

  useEffect(() => {
    const residencyUrl = residencyDetail ? residencyDetail.slug : '';

    navigate(`/${residencyUrl && `?r=${residencyUrl}`}`);
  }, [residencyDetail]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const residencyParams = searchParams.get('r');

    if (residencyParams) {
      fetchResidencyDetail(residencyParams);
      setDate(moment());
    } else {
      setDate(moment());
    }
  }, []);

  const handleResidencyClick = (residency) => {
    if (residency.length > 1) {
      return setResidenciesPopup(residency);
    } else {
      fetchResidencyDetail(residency.slug);
    }
  };

  const isEmpty = useMemo(() => {
    return Object.keys(residencyList.current_residencies || {}).every(
      (floor) => residencyList.current_residencies[floor]?.length === 0
    );
  }, [residencyList.current_residencies]);

  useEffect(() => {
    setTopListHeight(toplistref.current.clientHeight);
    document.documentElement.style.setProperty('--topListHeight', `${topListHeight}px`);
  }, [toplistref.current]);

  useEffect(() => {
    const handleScroll = () => {
      const rect = listref.current.getBoundingClientRect();
      document.documentElement.style.setProperty(
        '--overflowYState',
        rect.top === 0 ? 'scroll' : 'hidden'
      );
    };
    if (residencyref.current) {
      residencyref.current.addEventListener('scroll', handleScroll, {
        passive: true
      });
    }

    return () => {
      if (residencyref.current) {
        residencyref.current.removeEventListener('scroll', handleScroll, {
          passive: true
        });
      }
    };
  }, [residencyref]);

  const getResidency = (room) => {
    if (!residencyList.current_residencies) {
      return null;
    }

    const residencies = Object.values(residencyList.current_residencies)
      .flatMap((floorResidencies) => floorResidencies)
      .filter((residency) => residency.rooms.some((r) => r.slug === room.slug));

    return residencies.length === 0
      ? null
      : residencies.length === 1
        ? residencies[0]
        : residencies;
  };

  const mergedResidencyList = Object.keys(residencyList.current_residencies || {}).reduce(
    (acc, floor) => {
      return acc.concat(residencyList.current_residencies[floor]);
    },
    []
  );

  const uniqueResidencyList = mergedResidencyList.filter(
    (residency, index, self) => index === self.findIndex((r) => r.id === residency.id)
  );

  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      setResidencyDetail(undefined);
      setClosing(false);
    }, 300);
  };

  const dateAsString = useMemo(() => {
    if (moment(date).isSame(today, 'day')) return "Aujourd'hui";
    else if (moment(date).isSame(today.add(1, 'days'), 'day')) return 'Demain';
    else return formatDateString(date);
  }, [date]);

  return (
    <div className="frontoffice-css-scope">
      <Menu />
      <div className="page-wrapper ">
        <div className="residency-page" ref={residencyref}>
          <div className="list-wrapper" ref={listref}>
            <div className="top-list" ref={toplistref}>
              <div className="calendar-desktop">
                <CalendarCustom date={date} setDate={setDate} dateAsString={dateAsString} />
              </div>
              <div className="dropdowns-wrapper">
                <div className="calendar-mobile">
                  <CalendarCustom date={date} setDate={setDate} dateAsString={dateAsString} />
                </div>
                <DropDown
                  options={CONSTANT.residencyFilterOptions}
                  selectedOption={selectedType.dropdownLabel}
                  setSelectedOption={setSelectedType}
                  zIndexLabel="4"
                  zIndexOptions="3"
                />
                <div className="infos desktop">?</div>
                <Glossary device="desktop" setGlossaryOpen={setGlossaryOpen} />
                <div className="tag-mobile">
                  <DropDown
                    options={propertyContext}
                    selectedOption={selectedTag ? selectedTag.label : 'Tag'}
                    setSelectedOption={setSelectedTag}
                    zIndexLabel="2"
                    zIndexOptions="1"
                    showEmptyOption
                  />
                  <div className="infos mobile" onClick={() => setGlossaryOpen(true)}>
                    ?
                  </div>
                  {glossaryOpen && (
                    <Glossary
                      device="mobile"
                      setGlossaryOpen={setGlossaryOpen}
                      ref={residencyref}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="list-scroll">
              {loading ? (
                <div className="loading-wrapper desktop">
                  <ProgressSpinner
                    style={{ width: '50px', height: '50px' }}
                    strokeWidth="4"
                    animationDuration=".5s"
                  />
                </div>
              ) : isEmpty ? (
                <div className="aucun-resultat">Aucun résultat à afficher</div>
              ) : (
                residencyList && (
                  <>
                    <div className="yellow-bloc bientot">{dateAsString}</div>

                    {Object.keys(residencyList.current_residencies || {}).map((floor) => (
                      <div className="desktop" key={floor}>
                        {residencyList.current_residencies[floor].length === 0 ? null : (
                          <div className="floor">{getConstant('residencyFloor', floor).label}</div>
                        )}
                        <ResidencyList
                          residencies={residencyList.current_residencies[floor]}
                          handleResidencyClick={handleResidencyClick}
                          residencyDetail={residencyDetail}
                        />
                      </div>
                    ))}
                    <div className="mobile">
                      {mergedResidencyList && (
                        <ResidencyList
                          residencies={uniqueResidencyList}
                          handleResidencyClick={handleResidencyClick}
                          residencyDetail={residencyDetail}
                        />
                      )}
                    </div>
                  </>
                )
              )}{' '}
              {residencyList.current_month_residencies?.length === 0 ? null : (
                <div>
                  <div className="yellow-bloc bientot">
                    <IoIosArrowRoundDown size={30} /> {moment(date).format('MMMM')}
                    <IoIosArrowRoundDown size={30} />
                  </div>
                  <ResidencyList
                    residencies={residencyList.current_month_residencies}
                    handleResidencyClick={handleResidencyClick}
                    residencyDetail={residencyDetail}
                  />
                </div>
              )}
              {residencyList.next_month_residencies?.length === 0 ? null : (
                <div>
                  <div className="yellow-bloc bientot">
                    <IoIosArrowRoundDown size={30} /> {moment(date).add(1, 'months').format('MMMM')}
                    <IoIosArrowRoundDown size={30} />
                  </div>
                  <ResidencyList
                    residencies={residencyList.next_month_residencies}
                    handleResidencyClick={handleResidencyClick}
                    residencyDetail={residencyDetail}
                  />
                </div>
              )}
            </div>
          </div>
          {residencyDetail && (
            <>
              <ResidencyDetail
                residencyDetail={residencyDetail}
                closing={closing}
                setClosing={setClosing}
                handleResidencyClick={handleResidencyClick}
                setResidencyDetail={setResidencyDetail}
                handleClose={handleClose}
              />
              {window.innerWidth > 950 && (
                <ResidencyDetailPlan
                  residency={residencyDetail}
                  closing={closing}
                  handleClose={handleClose}
                />
              )}
            </>
          )}

          <BuildingPlan
            residencyDetail={residencyDetail}
            residencyList={residencyList}
            handleResidencyClick={handleResidencyClick}
            getResidency={getResidency}
          />
        </div>
        {residenciesPopup.length && (
          <ResidencyPopUp
            residencies={residenciesPopup}
            handleResidencyClick={handleResidencyClick}
            residencyDetail={residencyDetail}
            setResidenciesPopup={setResidenciesPopup}
            residenciesPopup={residenciesPopup}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default ResidencyPage;
