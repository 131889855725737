import React from 'react';
import Section from '../../../components/Backoffice/Display/Section';
import ResidencyEventItem from './ResidencyEventItem';

function ResidencyEventList({
  residency,
  handleResidencyEventCreate,
  handleResidencyEventUpdate,
  handleResidencyEventDelete,
  loading
}) {
  const createEventAction = {
    onClick: handleResidencyEventCreate,
    label: 'Ajouter un évènement',
    icon: 'pi-plus',
    loading
  };

  return (
    <Section label="Évènements ponctuels" actions={[createEventAction]}>
      <div className="flex flex-wrap gap-5">
        {residency.events?.length ? (
          residency.events.map((e) => (
            <ResidencyEventItem
              event={e}
              key={e.id}
              updateEvent={() => handleResidencyEventUpdate(e)}
              deleteEvent={() => handleResidencyEventDelete(e)}
            />
          ))
        ) : (
          <span className="empty-section-message">Aucun évènement à afficher...</span>
        )}
      </div>
    </Section>
  );
}

export default ResidencyEventList;
