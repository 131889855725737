import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignIn from './pages/public/SignIn';
import PrivateRouter from './pages/private/Router';
import { useAccountContext } from './contexts/AccountContext';
import ResidencyPage from './pages/public/ResidencyPage/ResidencyPage';
import AboutPage from './pages/public/AboutPage/AboutPage';
import MapPage from './pages/public/MapPage/MapPage';
import EconomyPage from './pages/public/EconomyPage/EconomyPage';
import LegalPage from './pages/public/LegalPage/LegalPage';
import Page404 from './pages/public/Page404/Page404';
import SetPassword from './pages/public/SetPassword';
import RecoverPassword from './pages/public/RecoverPassword';
import LocationPage from './pages/public/LocationPage/LocationPage';
import MobileCatcher from './pages/private/MobileCatcher';
import ArchivesPage from './pages/public/ArchivesPage/ArchivesPage';

function Router() {
  const { isLoggedIn } = useAccountContext();

  const getPrivateRouter = () => {
    if (!isLoggedIn) return null;
    return (
      <div className="backoffice-scope">
        <PrivateRouter />
      </div>
    );
  };

  return (
    <>
      {/* Private routes */}
      {getPrivateRouter()}
      <Routes>
        {/* Backoffice public routes */}
        <Route exact path="/login" element={<SignIn />} />
        <Route exact path="/password-setup/:token" element={<SetPassword />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route path="/essaimage" element={<LocationPage />} />
        {/* Frontoffice routes */}
        <Route exact path="/" element={<ResidencyPage />} />
        <Route exact path="/pourquoi-ce-registre" element={<AboutPage />} />
        <Route exact path="/et-apres" element={<MapPage />} />
        <Route exact path="/economie-contributive" element={<EconomyPage />} />
        <Route exact path="/mentions-legales" element={<LegalPage />} />
        <Route exact path="/archives" element={<ArchivesPage />} />
        <Route exact path="/mobile-catcher" element={<MobileCatcher />} />

        {!isLoggedIn && <Route exact path="*" element={<Page404 />} />}
      </Routes>
    </>
  );
}

export default Router;
