import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { Dialog } from 'primereact/dialog';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import { privateFetch } from '../../../utils/apiHelper';

function SearchBar() {
  const [showSearchDialog, setShowSearchModule] = useState(false);
  const [residencyList, setResidencyList] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const searchInputRef = useRef(null);
  const { navigationContext, setNavigationContext, openResidencyDetail } = useNavigationContext();

  useEffect(() => {
    if (!query) setResidencyList(navigationContext.recentResidencies);
    else fetchResidencyList();
  }, [query]);

  const fetchResidencyList = async () => {
    setLoading(true);
    await privateFetch('GET', `/residency/autocomplete/?q=${query}`).then((res) => {
      if (res) setResidencyList(res);
      setLoading(false);
    });
  };

  const handleResidencySelection = (residency) => {
    setShowSearchModule(false);
    openResidencyDetail(residency);
    setQuery('');
    setResidencyList(navigationContext.recentResidencies);
  };

  const displayHistory = !query;

  const header = (
    <IconField iconPosition="left">
      <InputIcon className={`pi ${loading ? 'pi-spin pi-spinner' : 'pi-search'}`}> </InputIcon>
      <InputText
        autoFocus
        ref={searchInputRef}
        placeholder="Rechercher un séjour"
        className="w-full text-lg"
        onChange={(e) => setQuery(e.target.value)}
      />
    </IconField>
  );

  return (
    <>
      <div
        onClick={() => setShowSearchModule(true)}
        className="flex flex-row items-center gap-x-3 rounded w-[300px] h-10 px-3 border border-slate-700 text-slate-300 cursor-text transition-all hover:text-slate-50 hover:border-slate-300 hover:bg-slate-900 ">
        <i className="pi pi-search text-lg mt-[2px]"></i>
        <span className="text-sm font-light">Rechercher un séjour...</span>
      </div>

      <Dialog
        header={header}
        visible={showSearchDialog}
        style={{ width: '600px', height: '50vh', marginBottom: '30vh', padding: 0 }}
        closable={false}
        draggable={false}
        dismissableMask={true}
        contentClassName="!p-3"
        headerClassName="!p-3"
        onHide={() => setShowSearchModule(false)}>
        <div className="flex flex-col">
          <h5 className="text-slate-500 uppercase text-sm font-semibold mb-1 ml-2">
            {displayHistory ? 'Séjours récents' : 'Résultats de la recherche'}
          </h5>
          {residencyList.map((r) => (
            <div
              onClick={() => handleResidencySelection(r)}
              key={r.id}
              className="w-full items-center flex flex-row gap-x-3 p-3 border-b last:border-b-0 text-slate-700 cursor-pointer hover:bg-slate-200 whitespace-nowrap">
              <i className={`pi ${displayHistory ? 'pi-history' : 'pi-search'} text-slate-500`} />
              <span className="pr-3">{r.label}</span>
            </div>
          ))}
          {!residencyList.length && (
            <span className="p-3 text-slate-500 text-sm italic">Aucun séjour à afficher...</span>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default SearchBar;
