import Menu from '../../../components/Frontoffice/Menu/Menu';
import Footer from '../../../components/Frontoffice/Footer/Footer';
import ResidencyDetail from '../../../components/Frontoffice/ResidencyDetail/ResidencyDetail';

import Map from './Map';
import './MapPage.scss';
import { publicFetch } from '../../../utils/apiHelper';
import { useEffect, useState } from 'react';
import '../ResidencyPage/ResidencyPage.scss';

function MapPage() {
  const [mapList, setMapList] = useState([]);
  const [closing, setClosing] = useState(false);
  const [residencyDetail, setResidencyDetail] = useState(undefined);

  const fetchMapList = async () => {
    await publicFetch('GET', `/map/`).then((response) => setMapList(response));
  };

  useEffect(() => {
    fetchMapList();
  }, []);

  const fetchResidencyDetail = async (residency) => {
    await publicFetch('GET', `/residency/${residency.slug}/`).then((response) => {
      setResidencyDetail(response);
    });
  };

  const handleResidencyClick = (residency) => {
    fetchResidencyDetail(residency);
  };

  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      setResidencyDetail(undefined);
      setClosing(false);
    }, 300);
  };

  return (
    <div className="frontoffice-css-scope">
      <Menu />
      <div className="page-wrapper">
        <div className="map-page-wrapper">
          <div className="et-apres-container">
            <h1>et après ?</h1>
            <p>
              Un mail est envoyé six mois après le départ des hôtes qui ont séjourné 3 mois à
              l'Hôtel Pasteur pour prendre des nouvelles. Les anciens hôtes pourront ainsi
              géolocaliser leur activité et donner à voir leur lieu d’atterrissage si des suites ou
              rebonds ont pu être trouvées. <br /> <br />
              Ainsi, une cartographie se dessine au gré du parcours des Hôtes. Elle est autant
              révélatrice de ce qui se joue au-delà des murs de l’Hôtel que source d’inspiration
              pour de nouveaux.elles arrivant.e.s. <br /> <br />
              Des histoires se fabriquent ainsi sur un temps long et cette page vous permet de voir
              comment l'expérimentation à l'Hôtel Pasteur essaime sur le territoire au local mais
              aussi au-delà.
            </p>
          </div>
          <div className="map-bloc">
            <Map mapList={mapList} handleResidencyClick={handleResidencyClick} />
          </div>
        </div>
        {residencyDetail && (
          <ResidencyDetail
            residencyDetail={residencyDetail}
            closing={closing}
            setClosing={setClosing}
            setResidencyDetail={setResidencyDetail}
            inMapPage
            handleClose={handleClose}
          />
        )}
      </div>

      <Footer />
    </div>
  );
}

export default MapPage;
