import React from 'react';
import Section from '../../../components/Backoffice/Display/Section';
import { getImage } from '../../../utils/imageHelper';

function ResidencyImageList({ residency }) {
  return (
    <Section label="Image">
      <div className="flex flex-wrap gap-5">
        <img
          src={residency.image?.file || getImage('img-ph.webp')}
          className="h-64 object-cover"
          alt="Image principale du séjour"
        />
      </div>
    </Section>
  );
}

export default ResidencyImageList;
