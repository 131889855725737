import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormInput from '../../../components/Backoffice/Form/FormInput';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import {
  getDateOrUndefined,
  getDateTimeStringOrUndefined,
  validateEmail
} from '../../../utils/stringHelper';
import { CONSTANT } from '../../../utils/constants';
import { usePropertyContext } from '../../../contexts/PropertyContext';
import { useRoomContext } from '../../../contexts/RoomContext';
import { FileUpload } from 'primereact/fileupload';
import { privateFetch } from '../../../utils/apiHelper';
import { Message } from 'primereact/message';
import slugify from 'react-slugify';
import { useAccountContext } from '../../../contexts/AccountContext';
import { getImage } from '../../../utils/imageHelper';
import Tag from '../../../components/Backoffice/Display/Tag';
import RichTextEditor from '../../../components/Backoffice/Form/RichTextEditor';

function ManageResidency({
  isOpen,
  handleClose,
  updateResidency,
  createResidency,
  loading,
  residency
}) {
  const fileUploadRef = useRef(null);
  const { propertyContext } = usePropertyContext();
  const { roomContext } = useRoomContext();
  const { isAdmin } = useAccountContext();

  const [isEmailUnique, setIsEmailUnique] = useState(true);

  // Form
  const [label, setLabel] = useState('');
  const [projectDeveloper, setProjectDeveloper] = useState('');
  const [type, setType] = useState(CONSTANT.backoffice.residency.typeList[0].value);
  const [state, setState] = useState('pending');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [properties, setProperties] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [description, setDescription] = useState('');
  const [motivation, setMotivation] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');

  const [image, setImage] = useState(undefined);

  useEffect(() => {
    if (residency) {
      setLabel(residency.label);
      setProjectDeveloper(residency.project_developer);
      setType(residency.type);
      setState(residency.state);
      setStartDate(getDateOrUndefined(residency.start_dt));
      setEndDate(getDateOrUndefined(residency.end_dt));
      setProperties(residency.properties);
      setDescription(residency.description);
      setMotivation(residency.motivation);
      setRooms(residency.rooms);
      setImage(residency.image);
      setEmail(residency.email);
      setWebsite(residency.website);
      setFacebookUrl(residency.facebook_url);
      setInstagramUrl(residency.instagram_url);
      setYoutubeUrl(residency.youtube_url);
    } else {
      setLabel('');
      setProjectDeveloper('');
      setType('');
      setState('pending');
      setStartDate(undefined);
      setEndDate(undefined);
      setProperties([]);
      setRooms([]);
      setDescription('');
      setMotivation('');
      setImage(undefined);
      setEmail('');
      setWebsite('');
      setFacebookUrl('');
      setInstagramUrl('');
      setYoutubeUrl('');
    }
  }, [residency]);

  useEffect(() => {
    if (!validateEmail(email)) {
      setIsEmailUnique(true);
    } else {
      checkEmailUnicity();
    }
  }, [email]);

  const checkEmailUnicity = async () => {
    await privateFetch('POST', `/cu/`, null, JSON.stringify({ email })).then((res) => {
      if (res && res.length == 0) {
        setIsEmailUnique(true);
      } else {
        setIsEmailUnique(false);
      }
    });
  };

  const uploadImage = async (imageForm) => {
    await privateFetch('POST', `/image/`, null, imageForm, false, true).then((res) => {
      setImage(res);
    });
  };

  const onUpload = (event) => {
    if (event.files.length == 1) {
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('name', slugify(file.name));
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const onValidate = () => {
    const residencyForm = {
      label,
      project_developer: projectDeveloper,
      type,
      state,
      start_dt: getDateTimeStringOrUndefined(startDate),
      end_dt: getDateTimeStringOrUndefined(endDate),
      description: description,
      motivation: motivation,
      properties: properties.map((p) => p.id),
      rooms: rooms.map((r) => r.id),
      image: image?.id,
      email,
      website,
      facebook_url: facebookUrl,
      instagram_url: instagramUrl,
      youtube_url: youtubeUrl
    };
    residency ? updateResidency(residencyForm) : createResidency(residencyForm);
  };

  const isLongResidency = type === 'long';
  const isResidencyCreation = !residency;
  const isLongResidencyCreation = isResidencyCreation && isLongResidency;
  const isLongResidencyModification = residency && isLongResidency;
  const isPublicEvent = type === 'event';

  const labelIsValid = label.length > 2;
  const descriptionIsValid = isResidencyCreation || description.length > 30;
  const motivationIsValid = isResidencyCreation || motivation.length > 30;
  const emailIsValid = validateEmail(email) && (!isLongResidencyCreation || isEmailUnique);
  const roomsIsValid = rooms.length;
  const propertiesIsValid = isResidencyCreation || properties.length;

  const formIsValid =
    labelIsValid &&
    type &&
    startDate &&
    endDate &&
    (isAdmin || descriptionIsValid) &&
    (state !== 'published' || propertiesIsValid) &&
    (isAdmin || propertiesIsValid) &&
    roomsIsValid &&
    (!isLongResidency || emailIsValid);

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider ${residency ? 'les modifications' : "l'ajout"}`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <Tag
          tag={{
            label: option.label,
            bgColor: option.bgColor
          }}
          rounded
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const emailHelpMessage = () => {
    if (!isEmailUnique && isLongResidencyCreation) {
      return (
        <Message
          severity="error"
          text="Cet email est déjà utilisé par un autre compte"
          className="!text-sm"
        />
      );
    } else if (isLongResidencyModification) {
      return (
        <Message
          severity="info"
          text="Si vous changez l'email de contact, l'identifiant de connexion (l'email) ne sera pas modifié."
          className="!text-sm"
        />
      );
    }
    return null;
  };

  return (
    <Sidebar
      style={{ width: 800 }}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${residency ? 'Modifier' : 'Ajouter '} un séjour`}</h3>
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput
            key="label"
            label="Nom du projet"
            required
            isValid={labelIsValid}
            helpMessage="250 caractères max">
            <InputText
              value={label}
              maxLength={250}
              placeholder={`Veuillez saisir le nom du projet`}
              onChange={(e) => setLabel(e.target.value)}
            />
          </FormInput>

          <FormInput
            key="projectDeveloper"
            label="Nom du porteur.euse de projet"
            helpMessage="250 caractères max">
            <InputText
              value={projectDeveloper}
              maxLength={250}
              placeholder={`Veuillez saisir le nom du porteur.euse de projet`}
              onChange={(e) => setProjectDeveloper(e.target.value)}
            />
          </FormInput>

          <FormInput key="type" label={'Type'} required isValid={type} hidden={!isAdmin}>
            <Dropdown
              id="type"
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANT.backoffice.residency.typeList}
              optionLabel="label"
              placeholder="Sélectionner un type de séjour"
              valueTemplate={dropdownTemplate}
              itemTemplate={dropdownTemplate}
            />
          </FormInput>

          <FormInput
            key="state"
            label={'État'}
            required
            isValid={state}
            helpMessage={'Le séjour doit être validé avant publication'}
            hidden={!isAdmin}>
            <Dropdown
              id="state"
              value={state}
              onChange={(e) => setState(e.value)}
              options={CONSTANT.backoffice.residency.stateList}
              optionLabel="label"
              placeholder="Sélectionner un état pour ce séjour"
              valueTemplate={dropdownTemplate}
              itemTemplate={dropdownTemplate}
              disabled={isResidencyCreation}
            />
          </FormInput>

          <FormInput label={'Date de début'} required isValid={startDate} hidden={!isAdmin}>
            <Calendar
              value={startDate}
              hideOnDateTimeSelect
              onChange={(e) => setStartDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
              showTime={isPublicEvent}
              stepMinute={15}
            />
          </FormInput>

          <FormInput label={'Date de fin'} required isValid={endDate} hidden={!isAdmin}>
            <Calendar
              value={endDate}
              hideOnDateTimeSelect
              onChange={(e) => setEndDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
              showTime={isPublicEvent}
              stepMinute={15}
            />
          </FormInput>

          <FormInput key="rooms" label={'Salles'} required isValid={roomsIsValid} hidden={!isAdmin}>
            <MultiSelect
              id="rooms"
              key="rooms"
              value={rooms}
              onChange={(e) => setRooms(e.value)}
              options={roomContext}
              optionLabel="label"
              placeholder="Veuillez sélectionner une ou plusieurs salles"
              display="chip"
            />
          </FormInput>

          <FormInput
            key="email"
            label="Email"
            required={isLongResidency}
            isValid={validateEmail(email)}
            helpMessage={"* L'email ne sera visible que par la conciergerie"}>
            <InputText
              value={email}
              size={250}
              type="email"
              placeholder={`Veuillez saisir l'email`}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormInput>

          {emailHelpMessage()}

          <FormInput
            key="properties"
            label={'Tags'}
            required={state === 'published' || !isAdmin}
            isValid={propertiesIsValid}>
            <MultiSelect
              id="properties"
              key="properties"
              value={properties}
              onChange={(e) => setProperties(e.value)}
              options={propertyContext}
              optionLabel="label"
              placeholder="Veuillez sélectionner des tags"
              display="chip"
            />
          </FormInput>

          <FormInput
            key="website"
            label="Site web"
            helpMessage={'Commençant par https:// ou http://'}>
            <InputText
              value={website}
              placeholder={`Veuillez saisir l'URL du site web`}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </FormInput>
          <FormInput
            key="facebook_url"
            label="Facebook"
            helpMessage={'Commençant par https:// ou http://'}>
            <InputText
              value={facebookUrl}
              placeholder={`Veuillez saisir l'URL de votre page Facebook`}
              onChange={(e) => setFacebookUrl(e.target.value)}
            />
          </FormInput>
          <FormInput
            key="instagram_url"
            label="Instagram"
            helpMessage={'Commençant par https:// ou http://'}>
            <InputText
              value={instagramUrl}
              placeholder={`Veuillez saisir l'URL de votre page Instagram`}
              onChange={(e) => setInstagramUrl(e.target.value)}
            />
          </FormInput>
          <FormInput
            key="youtube_url"
            label="Youtube"
            helpMessage={'Commençant par https:// ou http://'}>
            <InputText
              value={youtubeUrl}
              placeholder={`Veuillez saisir l'URL du votre chaine ou vidéo Youtube`}
              onChange={(e) => setYoutubeUrl(e.target.value)}
            />
          </FormInput>

          <FormInput label={'Qui suis-je ?'} required={!isAdmin} isValid={descriptionIsValid}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
              placeholder={'Quelques mots sur vous ...'}
            />
          </FormInput>

          <FormInput
            label={"Qu'est ce que je viens faire à Pasteur ?"}
            required={!isAdmin}
            isValid={motivationIsValid}>
            <RichTextEditor
              value={motivation}
              onChange={(value) => {
                setMotivation(value);
              }}
              placeholder={'Quelques mots sur vos projets à Pasteur ...'}
            />
          </FormInput>

          <FormInput
            key={'file'}
            label={'Image'}
            helpMessage={'20Mo max. et formats supportés : .jpg, .png'}>
            <img
              src={image?.file || getImage('img-ph.webp')}
              className="w-3/4 object-cover"
              alt="Image principale du séjour"
            />

            <div className="flex flex-row gap-x-3">
              {image && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={onClear}
                  severity="danger"
                />
              )}
              <FileUpload
                ref={fileUploadRef}
                auto
                disabled={image}
                mode="basic"
                name="file"
                accept="image/*"
                maxFileSize={CONSTANT.maxImageSize}
                customUpload
                uploadHandler={onUpload}
                chooseLabel="Depuis mon ordinateur"
              />
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageResidency;
