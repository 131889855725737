import React from 'react';
import { Tooltip } from 'primereact/tooltip';

function FormInput({ label, required, isValid, children, helpMessage, hidden }) {
  if (hidden) return null;
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-row w-full items-center justify-between">
        <div className="flex flex-row items-center gap-x-3">
          <label className="ml-1">{label}</label>
          {required && (
            <span
              data-pr-tooltip="Champs obligatoire"
              className={`pi custom-tooltip-label ${
                isValid ? 'pi-check-circle text-green-700' : 'pi-info-circle text-orange-500'
              }`}></span>
          )}
        </div>
        {helpMessage && (
          <small id="username-help" className="italic">
            {helpMessage}
          </small>
        )}
      </div>
      {children}
      <Tooltip target=".custom-tooltip-label" />
    </div>
  );
}

export default FormInput;
