import React from 'react';
import { CONSTANT } from '../../../utils/constants';
import DashboardSearch from '../../../components/Backoffice/Dashboard/DashboardSearch';
import DashboardFilter from '../../../components/Backoffice/Dashboard/DashboardFilter';
import { useDashboardContext } from '../../../contexts/DashboardContext';

function DashboardFilters() {
  const { dashboardContext, setDashboardFilter } = useDashboardContext();

  const handleFilter = (key, value) => {
    setDashboardFilter(key, value);
  };

  return (
    <div className="flex flex-row gap-x-3">
      <DashboardSearch
        handleSearch={(value) => handleFilter('query', value)}
        value={dashboardContext.filters.query}
      />
      <DashboardFilter
        key="type"
        options={CONSTANT.backoffice.residency.typeList}
        value={dashboardContext.filters.typeList}
        onChange={(value) => handleFilter('typeList', value)}
        emptyLabel="Filtrer par type"
      />
      <DashboardFilter
        key="state"
        options={CONSTANT.backoffice.residency.stateList}
        value={dashboardContext.filters.stateList}
        onChange={(value) => handleFilter('stateList', value)}
        emptyLabel="Filtrer par état"
      />
    </div>
  );
}

export default DashboardFilters;
