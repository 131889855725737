import React from 'react';
import moment from 'moment';
import { Card } from 'primereact/card';
import { SpeedDial } from 'primereact/speeddial';
import Tag from '../../../components/Backoffice/Display/Tag';
import { getImage } from '../../../utils/imageHelper';
import { formatDateTime } from '../../../utils/stringHelper';

function ResidencyEventItem({ event, updateEvent, deleteEvent }) {
  const actions = [
    {
      label: 'Modifier',
      icon: 'pi pi-pencil',
      command: () => {
        updateEvent();
      }
    },
    {
      label: 'Supprimer',
      icon: 'pi pi-trash',
      command: () => {
        deleteEvent();
      }
    }
  ];

  const header = (
    <div className="p-3 pb-0">
      <img
        alt="Card"
        className="h-[180px] object-cover rounded"
        src={event.image?.file || getImage('img-ph.png')}
      />
    </div>
  );

  const title = <div className="text-lg font-semibold text-slate-800">{event.label}</div>;

  const subtitle = (
    <div className="flex flex-col gap-y-3 pt-1">
      <div className="flex flex-row gap-x-3 items-center px-1 text-sm">
        <span className="pi pi-calendar "></span>
        <span>{`${formatDateTime(moment(event.start_dt))}`}</span>
        <span className="pi pi-chevron-right text-xs text-slate-400"></span>
        <span>{`${formatDateTime(moment(event.end_dt))}`}</span>
      </div>
      <div className="flex flex-row gap-x-3 items-center px-1 text-sm">
        <span className="pi pi-map-marker "></span>
        <span>{event.rooms.map((r) => r.label).join(' & ')}</span>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {event.properties.map((p) => (
          <Tag key={p.id} tag={{ label: p.label }} rounded />
        ))}
      </div>
    </div>
  );

  return (
    <Card
      title={title}
      subTitle={subtitle}
      header={header}
      className={`w-[400px] max-h-[600px] relative`}>
      {event.description ? (
        <div
          className="m-0 line-clamp-5 whitespace-pre-wrap mb-10"
          dangerouslySetInnerHTML={{
            __html: event.description
          }}></div>
      ) : (
        <p className="italic text-sm text-slate-400 mb-10">Aucune description n'a été fournie</p>
      )}

      <SpeedDial
        key="event-deletion"
        className="absolute bottom-5 right-3 text-xs "
        buttonClassName={'!h-9 !w-9'}
        model={actions}
        direction="up"
        rotateAnimation={false}
        showIcon="pi pi-ellipsis-v"
        hideIcon="pi pi-times"
      />
    </Card>
  );
}

export default ResidencyEventItem;
