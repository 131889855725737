import React, { useRef, useEffect } from 'react';
import ResidencyList from '../../../components/Frontoffice/ResidencyListItem/ResidencyList';

function ResidencyPopUp({
  residencies,
  handleResidencyClick,
  residencyDetail,
  setResidenciesPopup,
  residenciesPopup
}) {
  const popupRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, true);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, true);
    };
  }, [residenciesPopup]);

  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setResidenciesPopup([]);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup-wrapper" ref={popupRef}>
        <div className="popup-content">
          Plusieurs événements ont lieu dans cet espace, sélectionnez celui qui vous intéresse :{' '}
        </div>
        <div className="residency-pop-wrapper">
          <ResidencyList
            residencies={residencies}
            handleResidencyClick={handleResidencyClick}
            residencyDetail={residencyDetail}
            inPopUp
          />
        </div>
      </div>
    </div>
  );
}

export default ResidencyPopUp;
