import React, { useState, useEffect, useMemo } from 'react';
import Navbar from '../../../components/Backoffice/Navbar/Navbar';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import { privateFetch } from '../../../utils/apiHelper';
import { confirmDialog } from 'primereact/confirmdialog';
import ResidencyInformation from './ResidencyInformation';
import ResidencyEvents from './ResidencyEventList';
import ManageResidency from './ManageResidency';
import ManageResidencyEvent from './ManageResidencyEvent';
import ResidencyImageList from './ResidencyImageList';
import ResidencyAddress from './ResidencyAddress';
import { useAccountContext } from '../../../contexts/AccountContext';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import Section from '../../../components/Backoffice/Display/Section';
import ResidencyAccount from './ResidencyAccount';
import { useToastContext } from '../../../contexts/ToastContext';

function ResidencyPage() {
  const { navigationContext, setNavigationContext } = useNavigationContext();
  const { showToast } = useToastContext();
  const { isAdmin } = useAccountContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [residency, setResidency] = useState(undefined);
  const [residencyPanelOpen, setResidencyPanelOpen] = useState(false);

  // Event
  const [event, setEvent] = useState(undefined);
  const [eventPanelOpen, setEventPanelOpen] = useState(false);

  const isHost = useMemo(() => ['long', 'short'].includes(residency?.type), [residency]);
  const isLong = useMemo(() => ['long'].includes(residency?.type), [residency]);

  useEffect(() => {
    if (navigationContext.activeResidency && navigationContext.activeResidency.id) {
      fetchResidencyDetail();
    }
  }, [navigationContext.activeResidency]);

  const fetchResidencyDetail = async () => {
    await privateFetch('GET', `/residency/${navigationContext.activeResidency.id}/`).then((res) => {
      if (res) setResidency(res);
    });
  };

  const updateResidency = async (residencyForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/residency/${residency.id}/`,
      `Le séjour ${residencyForm.label} a bien été modifié.`,
      JSON.stringify(residencyForm)
    ).then(() => {
      setResidencyPanelOpen(false);
      setLoading(false);
      fetchResidencyDetail();
    });
  };

  const createEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/residency/${residency.id}/event/`,
      "L'évènement a bien été ajouté",
      JSON.stringify(eventForm)
    ).then(() => {
      fetchResidencyDetail();
      setLoading(false);
      setEventPanelOpen(false);
    });
  };

  const updateEvent = async (eventForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      `/residency/${residency.id}/event/${event.id}/`,
      `L'évènement ${eventForm.label} a bien été modifié.`,
      JSON.stringify(eventForm)
    ).then(() => {
      setEventPanelOpen(false);
      setLoading(false);
      fetchResidencyDetail();
    });
  };

  const deleteEvent = async (targetEvent) => {
    setLoading(true);
    await privateFetch(
      'DELETE',
      `/residency/${residency.id}/event/${targetEvent.id}/`,
      "L'évènement a bien été supprimé."
    ).then(() => {
      setLoading(false);
      fetchResidencyDetail();
    });
  };

  const deleteResidency = async () => {
    setLoading(true);
    await privateFetch(
      'DELETE',
      `/residency/${residency.id}/`,
      'La résidence a bien été supprimée.'
    ).then(() => {
      setLoading(false);
      setNavigationContext({
        ...navigationContext,
        activeApp: null,
        activeResidency: { label: '', id: null }
      });
      navigate('/private/dashboard');
    });
  };

  const handleResidencyEventUpdate = (targetEvent) => {
    setEvent(targetEvent);
    setEventPanelOpen(true);
  };

  const confirmEventDeletion = (targetEvent) => {
    setEventPanelOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer l'évènement ${targetEvent.label} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => deleteEvent(targetEvent)
    });
  };

  const confirmAccountDeletion = () => {
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le compte ${residency?.custom_user?.email} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: deleteAccount
    });
  };

  const createAccount = async () => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/residency/${residency.id}/account/`,
      'Le compte a bien été créé, et le mail de connexion envoyé.'
    ).then(() => {
      fetchResidencyDetail();
      setLoading(false);
      setEventPanelOpen(false);
    });
  };

  const deleteAccount = async () => {
    setLoading(true);
    await privateFetch(
      'DELETE',
      `/cu/${residency.custom_user.id}/`,
      'Le compte a bien été supprimé'
    ).then(() => {
      setLoading(false);
      fetchResidencyDetail();
    });
  };

  const confirmAccountCreation = () => {
    if (!residency?.email) {
      showToast(
        'error',
        `Impossible de créer un compte utilisateur. Merci de renseigner une adresse mail dans les informations générales de la résidence.`
      );
    } else {
      confirmDialog({
        message: `Êtes-vous sûr de créer un compte pour cette résidence ? Un mail sera envoyé à ${residency?.email} afin de choisir un mot de passe. `,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Oui',
        rejectLabel: 'Annuler',
        accept: createAccount
      });
    }
  };

  const confirmAskAdress = () => {
    if (!residency?.email) {
      showToast(
        'error',
        `Impossible de demander la nouvelle adresse postale. Merci de renseigner une adresse mail dans les informations générales de la résidence.`
      );
    } else {
      confirmDialog({
        message: `Un mail sera envoyé à ${residency?.email} afin de renseigner une nouvelle adresse postale. `,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Envoyer',
        rejectLabel: 'Annuler',
        accept: handleAskAddress
      });
    }
  };

  const handleAskAddress = async () => {
    setLoading(true);
    await privateFetch(
      'POST',
      `/residency/${residency.id}/add-location/`,
      'Le mail a bien été envoyé'
    ).then((response) => {
      if (response) {
        setLoading(false);
        fetchResidencyDetail(residency.id);
      }
    });
  };

  const handleCreateAddress = () => {
    window.open(`/#/essaimage/?token=${residency.location_token}`, '_blank');
  };

  return (
    <div className={`h-screen w-screen bg-slate-50 flex flex-col backoffice-scope`}>
      <Navbar />
      {residency && (
        <div className="page-wrapper !pb-40">
          <ResidencyInformation
            loading={loading}
            residency={residency}
            handleResidencyUpdate={() => setResidencyPanelOpen(true)}
          />
          <ResidencyImageList residency={residency} />

          {isLong && isAdmin && (
            <ResidencyAccount
              loading={loading}
              account={residency.custom_user}
              handleCreateAccount={confirmAccountCreation}
              handleDeleteAccount={confirmAccountDeletion}
            />
          )}
          {isLong && isAdmin && (
            <ResidencyAddress
              loading={loading}
              residency={residency}
              handleAskAddress={confirmAskAdress}
              handleCreateAddress={handleCreateAddress}
            />
          )}

          {isHost && (
            <ResidencyEvents
              loading={loading}
              residency={residency}
              handleResidencyEventCreate={() => {
                setEvent(undefined);
                setEventPanelOpen(true);
              }}
              handleResidencyEventUpdate={handleResidencyEventUpdate}
              handleResidencyEventDelete={confirmEventDeletion}
            />
          )}
          {isAdmin && (
            <Section label="Autres actions">
              <Button
                loading={loading}
                label="Supprimer le séjour"
                onClick={() => deleteResidency()}
                icon="pi pi-trash"
                severity="danger"
                outlined
                className="w-fit"
              />
            </Section>
          )}
        </div>
      )}

      <ManageResidency
        key={'residency'}
        loading={loading}
        residency={residency}
        isOpen={residencyPanelOpen}
        handleClose={() => {
          setResidencyPanelOpen(false);
        }}
        updateResidency={updateResidency}
      />

      {residency && (
        <ManageResidencyEvent
          key={'event'}
          loading={loading}
          residency={residency}
          event={event}
          isOpen={eventPanelOpen}
          handleClose={() => {
            setEventPanelOpen(false);
          }}
          updateEvent={updateEvent}
          createEvent={createEvent}
        />
      )}
    </div>
  );
}

export default ResidencyPage;
