import React, { createContext, useContext, useEffect, useState } from 'react';
import { publicFetch } from '../utils/apiHelper';

const RoomContext = createContext(null);

export const RoomContextProvider = ({ children }) => {
  const [roomContext, setRoomContext] = useState([]);

  useEffect(() => {
    fetchRoomList();
  }, []);

  const fetchRoomList = async () => {
    await publicFetch('GET', `/room/`).then((res) => {
      if (res) {
        setRoomContext(res);
      }
    });
  };

  return (
    <RoomContext.Provider value={{ roomContext, fetchRoomList }}>{children}</RoomContext.Provider>
  );
};

export const useRoomContext = () => {
  return useContext(RoomContext);
};
