import { NavLink } from 'react-router-dom';
import React from 'react';

function MenuItem({ menuItem, setMenuMobileIsOpen }) {
  return (
    <div className="menu-item">
      <div className="menu-title">
        <NavLink
          to={menuItem.link}
          onClick={() => setMenuMobileIsOpen(false)}
          className={({ isActive }) => (isActive ? 'active' : '')}>
          {menuItem.title}
        </NavLink>
      </div>
    </div>
  );
}

export default MenuItem;
