import Menu from '../../../components/Frontoffice/Menu/Menu';
import Footer from '../../../components/Frontoffice/Footer/Footer';
import FlyerVoyageur from '../../../assets/image/flyer-voyageur.webp';
import Grammage from '../../../assets/image/grammage.webp';
import PanicButton from '../../../assets/image/panic-button.webp';
import Vector from '../../../assets/image/vector.webp';
import './AboutPage.scss';

function AboutPage() {
  return (
    <div className="frontoffice-css-scope">
      <Menu />
      <div className="page-wrapper">
        <div className="about-page-container">
          <div className="registre-container">
            <h1>Pourquoi ce registre ? </h1>
            <br />
            <p>
              L’enjeu est de rendre lisible à tous·tes et sous une forme originale, la diversité des
              projets et des interactions des publics dans le lieu en prenant appui sur les données
              partagées par chacun.e.s. Se repérer dans cet hôtel Pasteur par l’espace ou par le
              temps. Naviguer dans un plan habité ou à travers un calendrier mais aussi documenter
              ce quotidien imprévisible et favoriser un essaimage du passage par ce lieu.
            </p>
            <br />
            <br />

            <h2>Sa genèse </h2>
            <br />
            <p>
              <b>
                Une première version a été réalisée par Marin Esnault et Quentin Ohrant, avec{' '}
                <a href="https://idlv.co/" target="_blank" rel="noreferrer">
                  le collectif Indien.ne.s dans la Ville
                </a>
              </b>
              , nous avons bénéficié du conseil et de l’expertise de deux sociétés :{' '}
              <b>La société Eegle</b> notamment dans la visualisation de données, la cartographie
              interactive et <b>La société Zenika</b> autour des pratiques agiles, du design de
              l’expérience utilisateur ainsi que l’écoconception web. Un processus de travail s’est
              mis en place dans une relation de confiance et d’échanges réciproques. Les étudiants
              de l’école Epitech ont contribué au projet au travers d’un hackathon réalisé en
              partenariat avec l’edulab Pasteur, l’association des fablabs de Rennes et l’edulab
              Rennes 2. <b>Une œuvre contributive « Comme un dessein à l’Hôtel Pasteur »</b> : Une
              fresque collective a été réalisée sur plusieurs mois, grâce à une interface web
              connectée à un traceur vertical disposé dans le hall commun de l’Hôtel Pasteur. Cette
              installation artistique a été conçue et installée par{' '}
              <b>
                le collectif IDLV (Charly Gutierrez, Arthur Masson, Stéphanie Melot et Marin
                Esnault)
              </b>{' '}
              dans le hall commun. Cette fresque révèle de manière sensible la participation du
              particulier dans le fonctionnement de Pasteur fait office de marqueur temporel
              “sensible” de l’Hôtel Pasteur comme un espace ouvert où l’on prend le temps, où l’on
              découvre, où l’on rencontre. <b></b>Ce projet a reçu le soutien de l’appel à projets
              créativité croisée – Rennes Métropole.
            </p>
            <br />
            <br />

            <p>
              Comme l’hôtel Pasteur est un mouvement permanent, le registre se devait aussi de
              l’être. Une nouvelle version voit le jour en 2024 réalisé par{' '}
              <b>
                <a href="https://collectifnode.fr/" target="_blank" rel="noreferrer">
                  le Collectif Node
                </a>
              </b>
              , rencontré comme hôte du lieu. Le Collectif Node est un collectif
              d'auto-entrepreneur·euses spécialisé en design et développement web. Iels accompagnent
              les entreprises de l'ESS et entreprises à mission, le monde associatif et celui de la
              culture dans la conception de leurs outils numériques. Leur crédo ? Faire du numérique
              avec le cœur pour des projets qui en ont.
            </p>
          </div>
          <div className="hackaton-container">
            <h1>hackaton : capter Pasteur</h1>
            <p className="intro">
              Comment créer de manière ludique des outils qui vont nous permettre de visualiser et
              de capter l’implication de chacun·e dans tous les aspects de la vie du lieu ?
            </p>
            <br />
            <p>
              Pour répondre à cette question complexe et poursuivre le chantier sur l’économie
              contributive, l’Hôtel Pasteur a accueilli en mars 2021 à la réouverture un hackathon
              coorganisé par l’<a href="https://edulabpasteur.fr/">édulab Pasteur</a>, les{' '}
              <a href="https://idlv.co/">IDLV</a>, l’école d’informatique{' '}
              <a href="https://www.epitech.eu/">Epitech</a>, et les{' '}
              <a href="https://labfab.fr/fabcity/">Labfabs rennais</a> avec le soutien des
              entreprises locales <a href="https://www.eegle.io/">Eegle</a> et{' '}
              <a href="https://www.zenika.com/">Zenika</a>.
            </p>
            <br />
            <p>
              Pendant une semaine, makers, hôtes, concierges, développeurs et développeuses,
              étudiant·e·s, curieux·se se sont lancé·s dans une aventure collaborative et ludique
              pour identifier des données pertinentes que nous pourrions valoriser dans le lieu et
              sur le site internet.
            </p>
            <br />
            <p>
              Ces quelques jours de réflexions fertiles ont abouti à la programmation de quatre
              prototypes et de dizaines de bonnes idées à développer dans le futur !
            </p>
            <br />
            <p>
              Tous les projets développés pendant le hackathon ne verront pas le jour, les fruits de
              ces temps d’intelligence collective restent cependant précieux et potentiellement
              activables à l’avenir.
            </p>
            <br />
            <p>
              Ce Hackathon s’est inscrit dans le cadre d’un “projet lauréat de l’appel à projet
              créativité croisée de Rennes Métropole".
            </p>
            <br />
            <h2>les prototypes</h2>
            <br />
            <img src={PanicButton} alt="panic button" />
            <br />
            <p>
              Le Panic Button qui pourrait compléter une interface sur site et en ligne pour mettre
              en lien les besoins de la conciergerie et des hôtes.
            </p>
            <br />
            <img src={Grammage} alt="grammage" />
            <br />
            <p>
              Grammage est un outil de mesure des déchets produits par l’Hôtel. Il permettra d’avoir
              un œil sur notre production collective déchets et de la modérer avec les hôtes.
            </p>
            <br />
            <img src={Vector} alt="Vector" />
            <br />
            <p>
              Vector, ce mignon groom robotique, pourrait permettre aux hôtes de produire un schéma
              ludique et sensible des réciprocités de leurs séjours à l’Hôtel.
            </p>
            <br />
            <img src={FlyerVoyageur} alt="flyer voyageur" />
            <p>
              <br />
              Équipé d’un QR code, le Flyer Voyageur sera donné à la fin d’un séjour pour que les
              hôtes puissent le scanner et se géolocaliser : ce qui dessinera une carte des graines
              que l’Hôtel Pasteur sème…
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutPage;
