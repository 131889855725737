import React from 'react';
import { useEffect } from 'react';

function BuildingRoom({ residency, room, handleResidencyClick, isMainPlan }) {
  const isMobile = window.innerWidth <= 950;

  const isChamber = (room) => {
    return room.slug.includes('chambre-23');
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--roomSize',
      isChamber(room) || isMobile ? '15px' : '27px'
    );

    document.documentElement.style.setProperty(
      '--flexDirection',
      isChamber(room) ? 'row ' : 'column'
    );
  }, []);

  return (
    <div key={room.label}>
      <div
        className="room-wrapper desktop"
        style={{
          top: isMainPlan ? room.positionY : room.RDpositionY,
          left: isMainPlan ? room.positionX : room.RDpositionX,
          flexDirection: isChamber(room) ? 'row' : 'column'
        }}>
        <div
          onClick={() => (residency && isMainPlan ? handleResidencyClick(residency) : null)}
          className="room-circle"
          style={{
            width: isChamber(room) ? 15 : 27,
            height: isChamber(room) ? 15 : 27,
            backgroundColor: residency ? '#96c1fa' : '#ececeb',
            cursor: residency && isMainPlan && 'pointer'
          }}></div>

        <div
          onClick={() => (residency && isMainPlan ? handleResidencyClick(residency) : null)}
          className="room-legend"
          style={{
            backgroundColor: residency ? '#96c1fa' : '#ececeb',
            marginTop:
              isChamber(room) && isMainPlan
                ? room.MPlegendPositionY
                : !isMainPlan && room.legendPositionY
                  ? room.legendPositionY
                  : -5,
            marginLeft:
              isChamber(room) && isMainPlan
                ? room.MPlegendPositionX
                : !isMainPlan && room?.legendPositionX,
            cursor: residency && isMainPlan && 'pointer'
          }}>
          {room.label}
        </div>
      </div>

      {isMainPlan ? (
        <div
          className="room-wrapper mobile"
          style={{ top: room.mobilePositionY, left: room.mobilePositionX }}>
          <div
            onClick={() => (residency && isMainPlan ? handleResidencyClick(residency) : null)}
            className="room-circle"
            style={{
              width: '15px',
              height: '15px',
              backgroundColor: residency ? '#96c1fa' : '#ececeb',
              cursor: residency && isMainPlan && 'pointer'
            }}></div>
        </div>
      ) : (
        <div
          className="room-wrapper mobile"
          style={{
            top: room.mobileRDPositionY,
            left: room.mobileRDPositionX,
            flexDirection: isChamber(room) ? 'row' : 'column'
          }}>
          <div
            className="room-circle"
            style={{
              width: 15,
              height: 15,
              backgroundColor: '#96c1fa'
            }}></div>

          <div
            className="room-legend"
            style={{
              backgroundColor: '#96c1fa',
              marginTop: room?.legendRDPositionY,
              marginLeft: room?.legendRDPositionX
            }}>
            {room.label}
          </div>
        </div>
      )}
    </div>
  );
}

export default BuildingRoom;
