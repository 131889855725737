import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import MarkerMap from '../../../assets/icon/marker-map.webp';

function Map({ mapList, handleResidencyClick }) {
  const customIcon = new Icon({
    iconUrl: MarkerMap,
    iconSize: [32, 32]
  });

  return (
    <MapContainer center={[48.113836, -1.673504]} zoom={12} minZoom={3}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {mapList.map((marker) => (
        <Marker
          position={[marker.location.latitude, marker.location.longitude]}
          icon={customIcon}
          style={{ zIndex: 0 }}
          key={marker.id}
          eventHandlers={{
            click: () => handleResidencyClick(marker)
          }}></Marker>
      ))}
    </MapContainer>
  );
}

export default Map;
