import React from 'react';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';

function Section({ label, actions, children }) {
  return (
    <>
      <div className="flex flex-col gap-y-3">
        <div className="flex flex-row w-full justify-between items-center">
          <h3 className="text-slate-700">{label}</h3>
          <div className="flex flex-row gap-x-3">
            {actions &&
              actions.map((action) => (
                <Button
                  key="additionnal"
                  size="small"
                  outlined
                  rounded
                  loading={action.loading}
                  label={action.label}
                  iconPos="left"
                  icon={`pi ${action.icon}`}
                  onClick={action.onClick}
                  className="text-slate-800"
                />
              ))}
          </div>
        </div>
        <div className="flex flex-col gap-y-5">{children}</div>
      </div>
      <Divider />
    </>
  );
}

export default Section;
