import React from 'react';
import CustomIcon from '../../components/Backoffice/CustomIcon';
import { getImage } from '../../utils/imageHelper';
import { CONSTANT } from '../../utils/constants';

function Layout({ children }) {
  return (
    <div className="backoffice-scope">
      <div className="h-screen w-screen bg-slate-50 text-slate-800 relative">
        <img
          src={getImage('background.jpg')}
          className="h-full w-full object-cover opacity-20"
          alt="Image de fond colorée"
        />
        <div className="h-full flex flex-col justify-between p-5 absolute top-0 left-0">
          <div className="flex flex-row gap-5 items-center">
            <CustomIcon icon="logo" alt="Logo de l'Hotel Pasteur" className="h-12 w-12 p-1" />
            <span className=" text-2xl font-medium">Hotel Pasteur - Le Registre</span>
          </div>
          {children}
          <div className="flex flex-row gap-3 text-slate-700">
            <span>{`Version ${CONSTANT.version}`}</span>
            <span>-</span>
            <span>
              {`Conçu, développé et maintenu par le `}{' '}
              <a
                href="https://collectifnode.fr"
                target="_blank"
                rel="noreferrer"
                className="text-yellow-500 hover:underline">
                Collectif Node
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
