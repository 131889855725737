import React, { createContext, useContext, useEffect, useState } from 'react';
import { publicFetch } from '../utils/apiHelper';

const PropertyContext = createContext(null);

export const PropertyContextProvider = ({ children }) => {
  const [propertyContext, setPropertyContext] = useState([]);

  useEffect(() => {
    fetchPropertyList();
  }, []);

  const fetchPropertyList = async () => {
    await publicFetch('GET', `/property/`).then((res) => {
      if (res) {
        setPropertyContext(res);
      }
    });
  };

  return (
    <PropertyContext.Provider value={{ propertyContext, fetchPropertyList }}>
      {children}
    </PropertyContext.Provider>
  );
};

export const usePropertyContext = () => {
  return useContext(PropertyContext);
};
