import React, { createContext, useContext, useEffect, useState } from 'react';
import { getDashboardContext, saveDashboardContext } from '../utils/dashboardHelper';
const DashboardContext = createContext(null);

const initial = {
  options: {
    offset: 0,
    rows: 50,
    page: 0
  },
  filters: {
    query: '',
    stateList: [],
    typeList: []
  }
};

export const DashboardContextProvider = ({ children }) => {
  const [dashboardContext, setDashboardContext] = useState(getDashboardContext() || initial);

  useEffect(() => {
    if (dashboardContext) {
      saveDashboardContext(dashboardContext);
    }
  }, [dashboardContext]);

  const setDashboardFilter = (key, value) => {
    setDashboardContext({
      ...dashboardContext,
      filters: {
        ...dashboardContext.filters,
        [key]: value
      }
    });
  };

  const setDashboardOptions = (options) => {
    setDashboardContext({
      ...dashboardContext,
      options
    });
  };

  const filteringIsActive = () => {
    return dashboardContext.filters !== initial.filters;
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardContext,
        setDashboardFilter,
        setDashboardOptions,
        filteringIsActive
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
