import React, { useEffect, useRef } from 'react';
import { RxCross1 } from 'react-icons/rx';

function Glossary({ device, setGlossaryOpen }) {
  const glossaryref = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (glossaryref.current && !glossaryref.current.contains(e.target)) {
      setGlossaryOpen(false);
    }
  };

  return (
    <div className={`glossaire ${device}`} ref={glossaryref}>
      {device === 'mobile' && (
        <div className="glossaire-title">
          <h2>Glossaire</h2>
          <RxCross1 size={30} onClick={() => setGlossaryOpen(false)} />
        </div>
      )}
      <ul>
        <li>
          <b>RDV public :</b> événement, rencontre, atelier ouvert au public (gratuit ou prix libre)
        </li>
        <li>
          <b>Séjour long :</b> expérimentation individuelle ou collective menée dans un espace dédié
          de l'hôtel Pasteur sur une durée de 1 à 3 mois. Cela implique d'être présent au quotidien,
          de partager l'expérience collective et de participer au fonctionnement du lieu
        </li>
        <li>
          <b>Séjour court :</b> action et/ou expérimentation, temps de transmission ou de visibilité
          publique mené dans le lieu à partir de 3 heures. Cela implique une autonomie
          d'installation et de rangement, de médiation sur sa propre action
        </li>
      </ul>
    </div>
  );
}

export default Glossary;
