import moment from 'moment';

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const formatDateString = (date) => {
  if (!date) return '';
  return moment(date).locale('fr').format('dddd D MMMM YYYY');
};

export const formatDateFromNowString = (date) => {
  if (!date) return '';
  return moment(date).locale('fr').fromNow();
};

export const formatDate = (date) => {
  if (!date) return '';
  return moment(date).format('DD/MM/YYYY');
};

export const formatDateTime = (date) => {
  if (!date) return '';
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const getDateTimeStringOrUndefined = (date) => {
  return date ? moment(date).toISOString() : undefined;
};

export const getDateStringOrUndefined = (date) => {
  return date ? moment(date).format('YYYY-MM-DD') : undefined;
};

export const getDateOrUndefined = (dateString) => {
  return dateString ? new Date(dateString) : undefined;
};
