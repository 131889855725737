import React from 'react';
import Data from '../../../components/Backoffice/Display/Data';
import Section from '../../../components/Backoffice/Display/Section';
import moment from 'moment';
import { formatDate } from '../../../utils/stringHelper';

function ResidencyAccount({ account, handleCreateAccount, handleDeleteAccount, loading }) {
  const createAccountAction = account
    ? {
        onClick: handleDeleteAccount,
        label: 'Supprimer le compte',
        icon: 'pi-trash',
        loading
      }
    : {
        onClick: handleCreateAccount,
        label: 'Créer un compte utilisateur',
        icon: 'pi-pencil',
        loading
      };

  return (
    <Section label="Compte utilisateur" actions={[createAccountAction]}>
      {account ? (
        <div className="data-section-row">
          <Data key="email" label={'Email'} value={account.email} large />
          <Data
            key="creation_dt"
            label={'Création du compte'}
            value={formatDate(account.creation_date)}
            large
          />
          <Data
            key="last_signin"
            label={'Dernière connexion'}
            value={account.last_signin_dt ? moment(account.last_signin_dt).fromNow() : 'Jamais'}
            large
          />
        </div>
      ) : (
        <span className="text-slate-400 italic font-extralight">Pas de compte utilisateur</span>
      )}
    </Section>
  );
}

export default ResidencyAccount;
