import React, { createContext, useContext, useMemo, useState } from 'react';

const AccountContext = createContext(null);

export const AccountContextProvider = ({ children }) => {
  const [accountContext, setAccountContext] = useState(null);

  const isLoggedIn = useMemo(() => accountContext !== null, [accountContext]);
  const isAdmin = useMemo(() => accountContext?.type === 'ADMIN', [accountContext]);

  return (
    <AccountContext.Provider value={{ accountContext, setAccountContext, isLoggedIn, isAdmin }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => {
  return useContext(AccountContext);
};
