import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { publicFetch } from '../../utils/apiHelper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import FormInput from '../../components/Backoffice/Form/FormInput';
import Layout from './Layout';
import useScreenSize from '../../hooks/useScreenSize';

function SetPassword() {
  const screenSize = useScreenSize();
  const [account, setAccount] = useState(undefined);
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (screenSize.width < 700) {
      navigate('/mobile-catcher');
    }
  }, [screenSize.width]);

  const fetchAccountDetail = async (token) => {
    await publicFetch('GET', `/user/${token}/`).then((res) => {
      setAccount(res);
    });
  };

  useEffect(() => {
    if (token) {
      fetchAccountDetail(token);
    } else {
      console.log('Error: token not found');
    }
  }, []);

  const handleSetPassword = async () => {
    setLoading(true);
    await publicFetch(
      'PUT',
      `/user/${token}/`,
      null,
      JSON.stringify({
        password: password
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
      } else {
        setDialogOpen(true);
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      }
    });
  };

  return (
    <Layout>
      <div className="flex flex-col gap-y-16 ml-36 w-[500px] mb-8">
        <div className="flex flex-col gap-3">
          <span className="text-5xl font-semibold">Bienvenue</span>
          <span className="text-2xl font-normal">Choisir votre nouveau mot de passe</span>
        </div>
        <div className="flex flex-col gap-6">
          <FormInput label={'Email'}>
            <InputText
              id="email"
              type="text"
              className="w-full mb-3"
              value={account?.email}
              disabled
              readOnly
            />
          </FormInput>
          <FormInput label={'Mot de passe'} required isValid={password.length > 7}>
            <InputText
              id="password"
              type="password"
              placeholder="Entrez votre mot de passe"
              className="w-full mb-3"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormInput>
          <FormInput
            label={'Répeter le mot de passe'}
            required
            isValid={passwordRepeat.length > 7 && passwordRepeat === password}>
            <InputText
              id="passwordRepeat"
              type="password"
              placeholder="Entrez à nouveau votre mot de passe"
              className="w-full mb-3"
              required
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
          </FormInput>
        </div>
        <Button
          label="Valider le mot de passe"
          icon="pi pi-user"
          loading={loading}
          disabled={password !== passwordRepeat || password.length < 8}
          onClick={handleSetPassword}
        />
      </div>

      <Dialog
        header="Configuration terminée"
        visible={dialogOpen}
        style={{ width: '30vw' }}
        draggable={false}
        closable={false}>
        <div className="flex flex-row gap-x-3 items-center pb-8">
          <div style={{ width: 30, marginRight: 15 }}>
            <ProgressSpinner style={{ width: '30px', height: '30px' }} />
          </div>
          <p>Vous allez être redirigé vers la page de connexion...</p>
        </div>
      </Dialog>
    </Layout>
  );
}

export default SetPassword;
