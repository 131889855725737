import { IoIosArrowRoundDown } from 'react-icons/io';
import { useState, useEffect, useRef } from 'react';
import './DropDown.scss';

function DropDown({
  options,
  selectedOption,
  setSelectedOption,
  zIndexLabel,
  zIndexOptions,
  showEmptyOption
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div className="dropdown-wrapper" ref={dropdownRef}>
      <label
        onClick={() => setIsOpen(!isOpen)}
        style={{
          zIndex: zIndexLabel
        }}
        className={
          selectedOption === 'Tag' || selectedOption === 'Séjours & rendez-vous'
            ? ''
            : 'dropdown-active'
        }>
        <div className="text-label">{selectedOption}</div>
        <IoIosArrowRoundDown size={20} style={{ minHeight: 20, minWidth: 20 }} />
      </label>
      {isOpen ? (
        <div className="dropdown-legend-wrapper" style={{ zIndex: zIndexOptions }}>
          {showEmptyOption && (
            <div
              className="dropdown-option"
              onClick={() => {
                setSelectedOption(undefined);
                setIsOpen(false);
              }}>
              Tous les Tags
            </div>
          )}
          {options.map((option, index) => (
            <div
              className="dropdown-option"
              onClick={() => {
                setSelectedOption(option);
                setIsOpen(false);
              }}
              key={index}>
              {option.label}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default DropDown;
