import React, { useEffect, useState } from 'react';
import './LocationPage.scss';
import { publicFetch } from '../../../utils/apiHelper';
import { useLocation } from 'react-router-dom';
import Map from './Map';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import CustomIcon from '../../../components/Backoffice/CustomIcon';
import Data from '../../../components/Backoffice/Display/Data';
import Menu from '../../../components/Frontoffice/Menu/Menu';
import Footer from '../../../components/Frontoffice/Footer/Footer';
import '../Page404/Page404.scss';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';

function LocationPage() {
  const [residencyDetail, setResidencyDetail] = useState(undefined);
  const location = useLocation();
  const [address, setAddress] = useState([]);
  const [query, setQuery] = useState('');
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const residencyParams = searchParams.get('token');
    if (residencyParams) {
      fetchResidencyDetail(residencyParams);
    } else {
      setError(true);
    }
  }, []);

  const fetchResidencyDetail = async (residencyToken) => {
    await publicFetch('GET', `/residency/token/${residencyToken}/`).then((response) => {
      setResidencyDetail(response);
    });
  };

  const updateAddress = async (addressForm) => {
    setLoading(true);
    await publicFetch(
      'PUT',
      `/residency/token/${residencyDetail.location_token}/`,
      "L'adresse a bien été ajouté à la résidence.",
      JSON.stringify(addressForm)
    ).then(() => {
      fetchResidencyDetail(residencyDetail.location_token);
      setAddress(undefined);
      setQuery('');
      setLoading(false);
      setSuccess(true);
    });
  };

  const onValidate = () => {
    const projectForm = {
      location: address.place_id
    };
    updateAddress(projectForm);
  };

  const fetchCityList = async (q) => {
    await publicFetch('GET', `/location/autocomplete/?query=${q}`).then((res) => {
      setFilteredAddresses(res);
    });
  };

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={"Valider l'adresse"}
        icon={'pi pi-check'}
        loading={loading}
        onClick={onValidate}
        severity="success"
        disabled={!address}
        rounded
      />
    </div>
  );

  return (
    <div className="map-page backoffice-scope">
      {error ? (
        <div className="frontoffice-css-scope">
          <div className="page-404">
            <Menu />
            <div className="page-404-container">
              <div>hôtel</div>
              <div className="error-404">404</div>
              <div>page non trouvée</div>
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        <>
          <div className="left-side">
            <div className="intro">
              <div className="flex flex-row items-center gap-x-5 mb-5">
                <CustomIcon
                  icon="logo"
                  alt="Logo de l'Hotel Pasteur"
                  className="h-8 w-8 text-white hover:text-yellow-500 cursor-pointer min-w-11"
                  onClick={() => navigate('/')}
                />
                <h1>Essaimage</h1>
              </div>

              <p>
                Tu as fait partie des hôtes de l’Hôtel Pasteur et nous souhaitons donner à voir
                votre nouveau lieu d’atterrissage ou/et géolocaliser votre activité aujourd'hui.
                <br />
                <br />
                Ainsi, une cartographie des essaimages se dessine au gré du parcours des hôtes de
                Pasteur. Toutes celles et ceux qui vous ont croisé ici peuvent vous retrouver.
                <br />
                <br />
                Pour permettre cela, merci à toi de nous indiquer votre adresse précise dans le
                champs prévu à cet effet sur la carte.
              </p>
            </div>

            <div className="residency-detail">
              <h1>{residencyDetail?.label}</h1>
              <Data
                key="label"
                label={'Porteur.euse de projet'}
                value={residencyDetail?.project_developer}
                large
              />
              <Data key="label" label={'Adresse'} value={residencyDetail?.location?.name} large />
              <div className="mobile flex flex-col gap-y-3 mb-80">
                <AutoComplete
                  field="name"
                  forceSelection
                  value={query}
                  suggestions={filteredAddresses}
                  completeMethod={(e) => fetchCityList(e.query)}
                  onChange={(e) => {
                    setQuery(e.value);
                    setAddress(undefined);
                  }}
                  onSelect={(e) => setAddress(e.value)}
                  inputClassName="!w-full"
                  placeholder="Saisir une adresse, une ville..."
                />
                <div className="w-full">{footerButtons}</div>
              </div>
            </div>
          </div>
          <div className="right-side desktop">
            <div className="bloc-autocomplete">
              <AutoComplete
                field="name"
                forceSelection
                value={query}
                suggestions={filteredAddresses}
                completeMethod={(e) => fetchCityList(e.query)}
                onChange={(e) => {
                  setQuery(e.value);
                  setAddress(undefined);
                }}
                onSelect={(e) => setAddress(e.value)}
                placeholder="Saisir une adresse, une ville..."
                inputClassName="!w-[400px] rounded-full px-3"
              />
              {footerButtons}
            </div>

            <Map
              latitude={residencyDetail?.location?.latitude}
              longitude={residencyDetail?.location?.longitude}
            />
          </div>

          <Dialog
            header="⭐ Merci ⭐"
            visible={success}
            className="w-full md:w-1/3"
            draggable={false}
            closable={false}>
            <p className="mb-8">Votre adresse a bien été enregistrée. Bon vent ! </p>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default LocationPage;
