import React from 'react';
import Data from '../../../components/Backoffice/Display/Data';

import Section from '../../../components/Backoffice/Display/Section';
import moment from 'moment';

function ResidencyAddress({ residency, handleAskAddress, handleCreateAddress, loading }) {
  const createAddressAction = {
    onClick: handleCreateAddress,
    label: 'Ajouter une adresse',
    icon: 'pi-plus',
    loading
  };

  const askAddressAction = {
    onClick: handleAskAddress,
    label: 'Demander la nouvelle adresse',
    icon: 'pi-send',
    loading
  };

  return (
    <Section label="Et après ? " actions={[askAddressAction, createAddressAction]}>
      <div className="data-section-row">
        <Data
          key="after"
          label={'Dernière adresse renseignée'}
          value={residency.location?.name}
          large
        />
        <Data
          key="email_dt"
          label="Dernière demande"
          value={
            residency.date_email_sent &&
            `Email envoyé le ${moment(residency.date_email_sent).format('L')}`
          }
          large
        />
      </div>
    </Section>
  );
}

export default ResidencyAddress;
